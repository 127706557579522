<template>
    <div>
      <div class="banner">
        <!-- <h3>CRM客户关系管理系统</h3>
        <p>Transformer intelligent testing product based on micro robot</p> -->
          <div  class='h3'>
            <img src='../../assets/img/dcmm_2.png'>
          </div>
      </div>
      <!-- <div class="product-nav-wrap">
        <div class="product-nav">
          <a
            v-for="item in navList"
            :key="item.name"
            @click="navHandle(item.url)"
            >{{ item.name }}</a
          >
        </div>
      </div> -->
      <div class="survey" id="survey">
         <div
          class="energy-title fadeOutUp"
          ref="animate"
          :class="{ fadeInUp: animateData.animate }"
        >
          <img class='aiOPS_bjimg' src="../../assets/img/dcmm_3.png" alt="" />
        </div>
        <div
          class="survey-title fadeOutUp"
          ref="animate"
          :class="{ fadeInUp: animateData.animate }"
        >
          DCMM的概念
        </div>
        <div class='topbox fadeOutUp'
           ref="animate"
          :class="{ fadeInUp: animateData.animate   }"
        >
              <p class='toptxt ' ><span></span>DCMM（DatamanagementCapabilityMaturityModel）是《数据管理能力成熟度评估模型》的简称，是我国首个数据管理领域正式发布的国家标准（GB/T36073-2018），旨在帮助企业利用先进的数据管理理念和方法，建立和评价自身数据管理能力，持续完善数据管理组织、程序和制度，充分发挥数据在促进企业向信息化、数字化、智能化发展方面的价值。</p>
        </div>
      </div>

      <div class="survey nr hidden" id="dcmm">
        <div style="height:50px"></div>
         <div
          class="energy-title fadeOutUp"
          ref="animate1"
          :class="{ fadeInUp: animateData.animate1 }"
        >
          <img class='aiOPS_bjimg' src="../../assets/img/dcmm_29.png" alt="" />
        </div>
        <div
          class="survey-title fadeOutUp"
          ref="animate1"
          :class="{ fadeInUp: animateData.animate1 }"
        >
          DCMM的内容
        </div>
        <div class='topbox fadeOutUp'
           ref="animate1"
          :class="{ fadeInUp: animateData.animate1 }"
        >
        <p class='toptxt ' ><span></span>DCMM结合数据生命周期管理各个阶段的特征，按照组织、制度、流程和技术对数据管理能力进行分析、总结，提炼出了数据战略、数据治理、数据架构、数据应用、数据安全、数据质量、数据标准和数据生存周期8个核心能力域，细分为28个过程域和445条能力等级标准。</p>
            <img class='topleft tran'  src="../../assets/img/dcmm_4.png" alt="" />
              
        </div>
      </div>


      <div class="survey dj hidden" id="leve">
         <div
          class="energy-title fadeOutUp"
          ref="animate2"
          :class="{ fadeInUp: animateData.animate2 }"
        >
          <img class='aiOPS_bjimg' src="../../assets/img/dcmm_6.png" alt="" />
        </div>
        <div
          class="survey-title fadeOutUp"
          ref="animate2"
          :class="{ fadeInUp: animateData.animate2 }"
        >
          DCMM的等级
        </div>
        <div class='topbox fadeOutUp'
           ref="animate2"
          :class="{ fadeInUp: animateData.animate2 }"
        >
        <p class='toptxt ' ><span></span>DCMM（DatamanagementCapabilityMaturityModel）是《数据管理能力成熟度评估模型》的简称，是我国首个数据管理领域正式发布的国家标准（GB/T36073-2018），旨在帮助企业利用先进的数据管理理念和方法，建立和评价自身数据管理能力，持续完善数据管理组织、程序和制度，充分发挥数据在促进企业向信息化、数字化、智能化发展方面的价值。</p>
        <div class="levebox" :class="{leved:islev}">
            <div class="l-start tran imgdiv imgdiv1">
                <img class='limg limg1 tran'   src="../../assets/img/dcmm_7.png" alt="" />
            </div>
            <div class="l-start tran imgdiv imgdiv2">
                <img class='limgv limg02 tran' :style="{  transitionDelay: '0.1s', }"  src="../../assets/img/dcmm_12.png" alt="" />
                <img class='limg limg2 tran'  :style="{  transitionDelay: '0.2s', }" src="../../assets/img/dcmm_8.png" alt="" />
            </div>
            <div class="l-start tran imgdiv imgdiv3">
                <img class='limgv limg03 tran' :style="{  transitionDelay: '0.3s', }"  src="../../assets/img/dcmm_12.png" alt="" />
                <img class='limg limg3 tran'  :style="{  transitionDelay: '0.4s', }" src="../../assets/img/dcmm_9.png" alt="" />
            </div>
            <div class="l-start tran imgdiv imgdiv4">
                <img class='limgv limg04 tran' :style="{  transitionDelay: '0.5s', }"  src="../../assets/img/dcmm_12.png" alt="" />
                <img class='limg limg4 tran'  :style="{  transitionDelay: '0.6s', }" src="../../assets/img/dcmm_10.png" alt="" />
            </div>
            <div class="l-start tran imgdiv imgdiv5">
                <img class='limgv limg05 tran' :style="{  transitionDelay: '0.7s', }" src="../../assets/img/dcmm_12.png" alt="" />
                <img class='limg limg5 tran' :style="{  transitionDelay: '0.8s', }" src="../../assets/img/dcmm_11.png" alt="" />
            </div>
        </div>
        </div>
      </div>

       <div class="features-wrap features-wrap2" id="features-wrap features-wrap2">
         <div
          class="energy-title fadeOutUp"
          ref="animate3"
          :class="{ fadeInUp: animateData.animate3 }"
        >
          <img class='aiOPS_bjimg' src="../../assets/img/dcmm_13.png" alt="" />
        </div>
        <div
          class="features-title  fadeOutUp" 
          ref="animate3"
          :class="{ fadeInUp: animateData.animate3 }"
        >
          DCMM价值
        </div>
       <div class="features-box fadeOutUp"
       :class="{ fadeInUp: animateData.animate3 }">
        <h4 class="h4">企业管理<div class="xian"></div></h4>
        <div class="features-content">
          <div
            class="features fadeOutUp"
            v-for="item in features"
            :key="item.name"
            :style="{
              transitionDelay: item.delay + 's',
            }"
            :class="{ fadeInUp: animateData[item.animate] }"
            :ref="item.animate"
          >
            <div class="features-icon">
              <img :src="item.icon" alt="" />
            </div>
            <div class="features-name" style="margin-top:5px !important">{{ item.name }}</div>
            <p class='features-txt'>{{item.txt}}</p>
          </div>
         
        </div>
        <div class="bombg">
            <h3 class="h3">行业竞争<div class="xian"></div></h3>
            <div>贯标企业在对外服务、试点项目、数字经济领域等，重要会议、学术交流、承接项目等均可获得更多的机会和优势。</div>
          </div>
       </div>
      </div>

       <div class="scenes-wrap scenes-wrap2" id="scenes-wrap">
        <div class="bg"></div>
          <div
          class="energy-title fadeOutUp"
          ref="animate4"
          :class="{ fadeInUp: animateData.animate4 }"
        >
          <img class='aiOPS_bjimg' src="../../assets/img/dcmm_23.png" alt="" />
        </div>
        <div
          class="scenes-title survey-title fadeOutUp"
          ref="animate4"
          :class="{ fadeInUp: animateData.animate4 }"
        >
          DCMM适用企业
        </div>
       
        <div class='topbox fadeOutUp '
           ref="animate4"
          :class="{ fadeInUp: animateData.animate4   }"
        >
              <p class='toptxt cpt' ><span></span>DCMM是针对企业数据管理和应用能力的评估框架，从标准本身讲，任何企业都可以申请，目前主要适用于两类：</p>
        </div>
      
        <div class="scenes">
          <div
            class="cp-4 fadeOutUp dh2"
            v-for="item in scenes2"
            :key="item.name"
            :style="{
              
              transitionDelay: item.delay + 's',
            }"
            :class="{ fadeInUp: animateData[item.animate] }"
            :ref="item.animate"
          >
          <div class="bg2"></div>
           <div 
          class='fadeOutUp'
           :class="{ fadeInUp: animateData[item.animate] }"
           :ref="item.animate"
            :style="{
              transitionDelay: (item.delay*1+0.3) + 's',
            }">
               <div class='cpimg'>
                <img :src="item.icon" alt="" />
              </div>
              <div class='cph3'>{{ item.name }}</div>
              <div class='cptxt'>{{ item.title }}</div>
          </div>
          </div>
        </div>
      </div>
  
      <div class=" scenes-wrap scenes-wrap3" id="scenes-wrap">
          <div
          class="energy-title fadeOutUp"
          ref="animate5"
          :class="{ fadeInUp: animateData.animate5 }"
        >
          <img class='aiOPS_bjimg' src="../../assets/img/dcmm_24.png" alt="" />
        </div>
        <div
          class="scenes-title survey-title fadeOutUp"
          ref="animate5"
          :class="{ fadeInUp: animateData.animate5 }"
        >
          DCMM评估条件
        </div>
        <div class='topbox fadeOutUp '
           ref="animate5"
          :class="{ fadeInUp: animateData.animate5   }"
        >
              <p class='toptxt cpt' ><span></span>目前，中国电子信息行业联合会对信息技术服务方提出成立时间、营业收入和从业人员等基本指标要求，要求如下：</p>
        </div>
      
         <div class="scenes2 fadeOutUp" style='margin-bottom:80px'
         ref="animate5"
          :class="{ fadeInUp: animateData.animate5   }">
          <h3  class="h3">信息技术服务方(乙方）评估审核要点</h3>
          <img class='simg ' :style="{  transitionDelay: '0.1s', }" src="../../assets/img/dcmm_25.png" alt="" />
          <p class="p">注：以上表格内容是最基本的要求，并不是满足这些要求即能获得相应的级别</p>
        </div>
        
      </div>


      <div class=" scenes-wrap scenes-wrap3 scenes-wrap4" id="scenes-wrap">
        <img class='s4img '  src="../../assets/img/dcmm_27.png" alt="" />
          <div
          class="energy-title fadeOutUp"
          ref="animate6"
          :class="{ fadeInUp: animateData.animate6 }"
        >
          <img class='aiOPS_bjimg' src="../../assets/img/dcmm_26.png" alt="" />
        </div>
        <div
          class="scenes-title survey-title fadeOutUp"
          ref="animate6"
          :class="{ fadeInUp: animateData.animate6 }"
        >
          DCMM政策补贴
        </div>
        <div class='topbox fadeOutUp '
           ref="animate6"
          :class="{ fadeInUp: animateData.animate6   }"
        >
              <p class='toptxt cpt' ><span></span>目前共约16个省、32个地市、25个地区出台了DCMM评估奖励补贴政策，相关政策按照首次获得DCMM的认证等级，分别给予20万元-60万元的一次性资金补助（具体以各地方的补贴政策为准）。</p>
        </div>
      
         <div class="scenes2 fadeOutUp" style='margin-bottom:80px'
         ref="animate6"
          :class="{ fadeInUp: animateData.animate6   }">
          <img class='simg ' :style="{  transitionDelay: '0.1s', }" src="../../assets/img/dcmm_28.png" alt="" />
        </div>
        
      </div>


    </div>
  </template>
  
  <script>
  import { isElementNotInViewport } from "../../utils/index.js";
  export default {
    data() {
      return {
        istwo:true,
        navList: [
          {
            name: "产品概况",
            url: "survey",
          },
          {
            name: "产品特点",
            url: "features-wrap",
          },
          {
            name: "应用场景",
            url: "scenes-wrap",
          },
        ],
        product: [
          {
            name: "3D虚拟模型",
            icon: require("../../assets/images/product-icon_6.png"),
            animate: "animate3",
            delay: 0.1,
          },
          {
            name: "物联网感知",
            icon: require("../../assets/images/product-icon_7.png"),
            animate: "animate4",
            delay: 0.2,
          },
          {
            name: "三维可视化智能管控平台",
            icon: require("../../assets/images/product-icon_8.png"),
            animate: "animate5",
            delay: 0.3,
          },
        ],
        features: [
         {
            name: "提高数据管理方法",
            icon: require("../../assets/img/dcmm_14.png"),
            animate: "animate8",
            txt:"帮助企业科学有效的掌握数据管理方法，发现问题找到差距，给出企业提高数据管理能力的路径。",
            delay: 0.3,
          },
          {
            name: "提升内部管理",
            icon: require("../../assets/img/dcmm_15.png"),
            animate: "animate7",
            txt:"帮助企业提升内部管理，提高数据作为单位核心战略资源的地位",
            delay: 0.2,
          },
          {
            name: "提高人员技能",
            icon: require("../../assets/img/dcmm_16.png"),
            animate: "animate12",
            txt:"帮助企业提高人员技能，推动企业数据管理人才队伍建设",
            delay: 0.1,
          },
          {
            name: "提高市场竞争门槛",
            icon: require("../../assets/img/dcmm_17.png"),
            animate: "animate12",
            txt:"帮助企业提高市场竞争门槛，促进数据要素价值释放",
            delay: 0,
          },
          
        ],
        scenes: [
          {
            name: "自动化",
            txt:"可一站式实现界面、接口、移动端自动化测试",
            icon: require("../../assets/img/robot_1.png"),
            animate: "animate9",
            delay: 0.1,
          },
          {
            name: "高覆盖",
            txt:"可自动化生成高覆盖的测试用例",
            icon: require("../../assets/img/robot_2.png"),
            animate: "animate10",
            delay: 0.2,
          },
          {
            name: "更方便",
            txt:"可脚本与数据分离，便于维护和共享",
            icon: require("../../assets/img/robot_3.png"),
            animate: "animate11",
            delay: 0.3,
          },
          {
            name: "更高效",
            txt:"可与其他系统集成，支持二次开发和私有化部署",
            icon: require("../../assets/img/robot_4.png"),
            animate: "animate24",
            delay: 0.4,
          },
        ],
         scenes2: [
         
          {
            name: "数据拥有方(甲方)",
            title:"数据拥有方(甲方)，自身拥有大量数据，需要对自身数据进行管理。如：银行、证券机构、电信运营商、工业企业、政务数据中心等",
            bg: require("../../assets/img/dcmm_21.png"),
            icon: require("../../assets/img/dcmm_19.png"),
            animate: "animate21",
            delay: 0.1,
          },
          {
            name: "信息技术服务方(乙方)",
            title:"信息技术服务方(乙方)，自身并不拥有大量数据但对外输出数据管理的能力和相关服务。如：数据开发运营商数据中台技术提供商、数据管理技术解决方案提供商",
            bg: require("../../assets/img/dcmm_21.png"),
            icon: require("../../assets/img/dcmm_20.png"),
            animate: "animate22",
            delay: 0.2,
          }
        ],
        islev:true,
        animateData: {
          animate: false,
          animate1: false,
          animate2: false,
          animate3: false,
          animate4: false,
          animate5: false,
          animate6: false,
          animate7: false,
          animate8: false,
          animate9: false,
          animate10: false,
          animate11: false,
  
        },
      };
    },
    mounted() {
      this.scrollToTop();
      // 监听滚动事件
      window.addEventListener("scroll", this.scrollToTop);
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.scrollToTop);
    },
    methods: {
      navHandle(id) {
        let target = document.getElementById(id);
        target.scrollIntoView();
      },
      // 滚动事件
      scrollToTop() {
     
        for (let i in this.$refs) {
           if( !isElementNotInViewport(this.$refs[i])) console.log( !isElementNotInViewport(this.$refs[i]),i)
          !isElementNotInViewport(this.$refs[i])
            ? (this.animateData[i] = true)
            : (this.animateData[i] = false);
  
            if(i=='animate2' && !isElementNotInViewport(this.$refs[i])){
                setTimeout(()=>{
                  this.islev = false
                  
                },1000)
            }
            if(i=='animate2' && isElementNotInViewport(this.$refs[i])){
                  this.islev = true
            }
        }
      },
      scenesArrowHandle(val) {
        if (val == 1) {
          this.$refs.swiper.prev();
        } else {
          this.$refs.swiper.next();
        }
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .banner {
      width:100%;
      height:433px;
      background: url(../../assets/img/dcmm_1.png) no-repeat center center;
      background-size:1920px 433px;
      .h3{
          position: relative;
          top:160px;
          width:1180px;
          margin: 0px auto;
      }
      h3{
          width: 578px;
          height: 45px;
          font-size: 34px;
          color: #FFFFFF;
          line-height: 45px;
          width:1180px;
          font-weight: 400;
          margin: 0px auto;
          position: relative;
          top:160px;
      } 
      p{
          height: 19px;
          font-size: 14px;
          color: rgba(255, 255, 255, .49);
          line-height: 19px; 
          width:1180px;
          margin: 0px auto;
          position: relative;
          top:182px;
          
      }
  }
  
  .scenes {
    margin-top: 38px;
  
    .scenes-item {
      width: 1181px;
      height:184px;
      background:url(../../assets/img/productRobot_17.png) no-repeat;;
      background-size:100% 100%;
      h3 {
          height: 26px;
          font-size: 26px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 26px;
          text-align: center;
          padding-top:84px;
      }
  
    
    }
  }
  .energy-title{
    text-align: center;
  }
  .survey-title{
    height: 37px;
    font-size: 28px;
    color: #414B59;
    line-height: 37px;
    position: relative;
    top:-23px;
  }
  .features-title{
    font-size: 28px;
    color: #FFFFFF;
    line-height: 37px;
    position: relative;
    top:-23px;
  }
  .features-wrap{
    height: 479px;
    background: #396BE3;
  
  }
  .features-wrap2{
    height: 646px;
    background: url(../../assets/img/dcmm_18.png) no-repeat center center;
    background-size: 1925px 646px;
    .features-title{
        font-size: 28px;
        color: #414B59;
        line-height: 37px;
        position: relative;
        top:-23px;
    }
    .features-box{
      width: 1179px;
      height: 455px;
      background: #FFFFFF;
      border-radius: 8px;
      margin:0 auto;
      overflow: hidden;
      .h4{
        font-size: 22px;
        font-family: MicrosoftYaHei;
        color: #414B59;
        line-height: 29px;
        position: relative;
        padding:30px 0 0 20px;
        z-index: 1;
        .xian{
          width: 91px;
          height: 8px;
          background: #3E76E0;
          position: absolute;
          left:20px;
          bottom:3px;
          z-index: -1;
        }
      }
      .features-name{
        height: 21px;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #414B59;
        line-height: 21px;
      }
      .features-txt{
        width: 221px;margin:0 auto;
        height: 75px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #95989D;
        line-height: 25px;
        margin-bottom:10px;
      }
      .features-icon{
        height:86px;
      }
    }
    .features-content{
      
    }
    .bombg{
        height: 171px;
        background: #3E76E0;
      .h3{
          font-size: 22px;
          font-family: MicrosoftYaHei;
          color: #FFFFFF;
          line-height: 29px;
          position: relative;
          z-index: 1;
          padding:30px 0 14px 18px;
        .xian{
          width: 91px;
          height: 8px;
          background: rgb(66, 191, 232);
          position: absolute;
          left:18px;
          bottom:17px;
          z-index: -1;
        }
      }
      div{
        height: 32px;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #FFFFFF;
        line-height: 32px;
        padding-left:18px;
      }
    }
  
  }
  .features{
    width:284px !important;
    padding:0;
    .features-icon{
  
    }
    .features-name{
      margin-top: 16px !important;
      height: 48px;
      color: #FFFFFF;
    }
    .features-txt{
      height: 90px;
      font-size: 14px;
      color: rgba(255, 255, 255, .66);
      line-height: 30px;
    }
  }
  .nr{
    background: url(../../assets/img/dcmm_5.png) no-repeat center center;
    background-size:1934px 868px;
    padding:0 !important;
  }
  .survey{
    padding:50px 0;
  }
  .scenes .bomlist{
    width: 280px;
    height: 297px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 9px rgba(132,181,255,0.08);
    border-radius: 4px;
    padding-top:40px;
    img{
      width:78px;
      margin: 0 auto;
    }
    .yytitle{
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      color: #414B59;
      line-height: 26px;
      margin-top:17px;
    }
    .yytext{
      text-align: center;
      width: 230px;
      height: 60px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #999999;
      line-height: 24px;
      margin:0 auto;
      margin-top:11px;
      
    }
  }
  .yytxt{
    font-size: 18px !important;
    color: #909399 !important;
  }
  .toptxt{
      width: 100%;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #909399;
      line-height: 32px;
      text-align: center;
      span{
          color:rgba(65, 75, 89, 1);
          font-size:20px;
      }
  }
  .cp-item{
    width:384px;
    height:507px;
    text-align: center;
    padding-top:142px;
    background:#396BE3;
    .cpimg{
      
    }
    .cph3{
      height: 30px;
      font-size: 18px;
      color: #FFFFFF;
      line-height: 24px;
      margin:24px 0 7px 0;
      
    }
    .cptxt{
        width: 280px;
        height: 48px;
        font-size: 14px;
        color: rgba(255, 255, 255, .9);
        line-height: 24px;
        margin: 0 auto;
    }
  }
  .cp-item:hover{
      background:#396BE3;
      background-image:none !important;
    }
  // 产品简介图
  .topbox{
      width:1092px;
      margin:0 auto;
      text-align: center;
      .topleft{
          width: 603px;
          height: 603px;
        //   background: url(../../assets/img/productRobot_2.png) no-repeat;
            margin:23px auto;
        opacity: 1;
          position: relative;
          img{
              position: absolute;
          }
          .topleftimg1{
              top:280px;
              right:-13px;
          }
          .topleftimg2{
              top:280px;
              left:-13px;
          }
          .topleftimg3{
              top:-57px;
              left:154px;
          }
      }
  }
  .topbox2{
      .topleft{
            opacity: 0; 
          .topleftimg1{
              top:210px;
              right:57px;
              opacity: 0; 
          }
          .topleftimg2{
              top:210px;
              left:57px;
              opacity: 0; 
          }
          .topleftimg3{
              top:43px;
              left:154px;
              opacity: 0; 
          }  
      }
  }
  .tran{
      transition: all 1s ease-in;
      -webkit-transition:all 1s ease-in;
    }
    .scenes .scenes-item img:hover {
      transform: scale(1) !important;
    }
    .scenes .scenes-item:hover {
      transform: scale(1.1) !important;
    }

// 等级
.levebox{
    width:882px;
    height:507px;
    margin:0 auto;
    overflow: hidden;
    .imgdiv2,.imgdiv3,.imgdiv4,.imgdiv5{
        position: relative;
        margin-top:-40px;
    }
   
    .limgv{
        width:61px;
        height:65px;
    }
    .limg02{
        position: relative;
        top:-30px;
        left:235px;
    }
    .limg03{
        position: relative;
        top:-30px;
        left:165px;
    }
    .limg04{
        position: relative;
        top:-30px;
        left:95px;
    }
    .limg05{
        position: relative;
        top:-30px;
        left:20px;
    }
    .limg5{
        margin-left:-70px;
        margin-top:5px;
    }
   
    .limg4{
        margin-left:5px;
        margin-top:5px;
    }
    .limg3{
        margin-left:80px;
        margin-top:5px;
    }

    .limg2{
        margin-left:145px;
        margin-top:5px;

    }
    .limg1{
        margin-left:280px;
        position: relative;
    }
 
    
}
.leved{
  .limg1,.limg2,.limg3,.limg4,.limg5{
    position: relative;
        opacity: 0;
    }
    .limg02,.limg03,.limg04,.limg05{
      position: relative;
        opacity: 0;
    }
    .limgv{
      opacity: 0;
    }
}

.scenes-wrap2{
  background: #F6F8FD;
  position: relative;
  .bg{
    background: url(../../assets/img/dcmm_22.png) no-repeat;
    background-size:100% 100%;
    width:1920px  ;
    height:326px;
    position: absolute;
    top:0;
    left:0;
    z-index: -1;
  }
  .cpt{
    height: 32px;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 32px;
  }
  .survey-title{
    color: #FFFFFF;
  }
  .cp-4{
    width: 581px;
    height: 249px;
    box-shadow: 0px 0px 20px 10px rgba(0,0,0,0.05);
    border-radius: 8px;
    background: #fff;
    z-index: 1;
    position: relative;
  }
  .bg2{
    background: url(../../assets/img/dcmm_21.png) no-repeat;
    background-size:99px 122px;
    width:99px;
    height:122px;
    position: absolute;
    top:0;
    left:452px;
  }
  .cpimg{
    padding:19px 0 6px 39px;
  }
  .cph3{
    height: 31px;
    font-size: 24px;
    font-family: MicrosoftYaHei;
    color: #414B59;
    line-height: 31px;
    margin:0 0 9px 27px;
  }
  .cptxt{
    width: 533px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #909399;
    line-height: 28px;
    margin-left:27px;
  }
  .topbox {
    width: 1092px;
    margin: 0 auto;
    margin-bottom: 25px;
    margin-top: -7px;
    text-align: center;
  }
  .scenes {
      margin-top: 0;
  }
}
.scenes-wrap3{
  height:673px;
  background:#fff;
  width: 100%;
  .topbox{
    margin-bottom:30px;
  }
  .scenes2{
    text-align: center;
    .h3{
      text-align: center;

    }
    .simg{
      margin:0 auto;
      margin-top:-30px;
    }
    .p{
      width: 1160px;
      height: 24px;
      font-size: 18px;
      font-family: MicrosoftYaHei;
      color: #999999;
      line-height: 24px;
      margin:0 auto;
      text-align: left;
      margin-top:-30px;
    }
  }
  

}
.scenes-wrap4{
  height:406px;
  background:#e7f3ff;
  // background: url(../../assets/img/dcmm_27.png) no-repeat;
  background-size:1920px 406px;
  padding-top:50px;
  position: relative;
  .s4img{
    position:absolute;
    bottom:0;
    left:50%;
    margin-left:-267px;
  }
  .topbox {
    margin-top:-20px;
  }
  .scenes2{
    .simg{
      margin-top:22px;
    }
    
  }
}

.hidden{
  overflow: hidden;
}
.dh2{
    transform: scale(1);
    transition-property:all;
    transition-duration:.3s;
  }
  .dh2:hover{
    transform: scale(1.02);
  }
  </style>