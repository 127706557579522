var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"product-nav-wrap"},[_c('div',{staticClass:"product-nav"},_vm._l((_vm.navList),function(item){return _c('a',{key:item.name,on:{"click":function($event){return _vm.navHandle(item.url)}}},[_vm._v(_vm._s(item.name))])}),0)]),_c('div',{staticClass:"survey",attrs:{"id":"survey"}},[_c('div',{ref:"animate",staticClass:"survey-title fadeOutUp",class:{ fadeInUp: _vm.animateData.animate }},[_vm._v(" 产品概况 ")]),_c('div',{staticClass:"survey-content"},[_vm._l((_vm.product),function(item){return _c('div',{key:item.name,ref:item.animate,refInFor:true,staticClass:"product fadeOutUp",class:{ fadeInUp: _vm.animateData[item.animate] },style:({
          transitionDelay: item.delay + 's',
        })},[_c('img',{attrs:{"src":item.icon,"alt":""}}),_c('span',[_vm._v(_vm._s(item.name))])])}),_c('div',{staticClass:"dotted"}),_c('img',{staticClass:"survey-left",attrs:{"src":require("../../assets/images/survey-left.png"),"alt":""}}),_c('img',{staticClass:"survey-right",attrs:{"src":require("../../assets/images/survey-right.png"),"alt":""}})],2),_vm._m(1)]),_c('div',{staticClass:"features-wrap",attrs:{"id":"features-wrap"}},[_c('div',{ref:"animate1",staticClass:"features-title fadeOutUp",class:{ fadeInUp: _vm.animateData.animate1 }},[_vm._v(" 产品特点 ")]),_c('div',{staticClass:"features-content"},_vm._l((_vm.features),function(item){return _c('div',{key:item.name,ref:item.animate,refInFor:true,staticClass:"features fadeOutUp",class:{ fadeInUp: _vm.animateData[item.animate] },style:({
          backgroundImage: 'url(' + item.bg + ')',
          transitionDelay: item.delay + 's',
        })},[_c('div',{staticClass:"features-icon"},[_c('img',{attrs:{"src":item.icon,"alt":""}})]),_c('div',{staticClass:"features-name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticStyle:{"font-size":"14px","color":"rgba(255,255,255,.8)","margin-top":"10px"}},[_vm._v(_vm._s(item.text))])])}),0)]),_c('div',{staticClass:"scenes-wrap",attrs:{"id":"scenes-wrap"}},[_c('div',{ref:"animate2",staticClass:"scenes-title fadeOutUp",class:{ fadeInUp: _vm.animateData.animate2 }},[_vm._v(" 应用场景 ")]),_c('div',{ref:"animate14",staticClass:"scenes-subtitle fadeOutUp",class:{ fadeInUp: _vm.animateData.animate14 },staticStyle:{"transition-delay":"0.2s"}},[_vm._v(" 作业人员安全管控 ")]),_c('div',{staticClass:"scenes"},_vm._l((_vm.scenes),function(item){return _c('div',{key:item.name,ref:item.animate,refInFor:true,staticClass:"scenes-item fadeOutUp",class:{ fadeInUp: _vm.animateData[item.animate] },style:({
          transitionDelay: item.delay + 's',
        })},[_c('div',[_c('img',{attrs:{"src":item.icon,"alt":""}})]),_c('span',[_vm._v(_vm._s(item.name))])])}),0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("../../assets/images/hat-banner_4.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"survey-bottom"},[_c('img',{attrs:{"src":require("../../assets/images/hat-img_1.png"),"alt":""}}),_c('div',[_vm._v(" 基于HSE管理体系的作业人员"),_c('br'),_vm._v("安全管控解决方案 ")])])}]

export { render, staticRenderFns }