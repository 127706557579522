<template>
  <div>
    <div class="banner">
      <!-- <h3>TMS自动化测试系统</h3>
      <p>Rail transit intelligent operation and maintenance products based on Vibration Monitoring</p> -->
      <div  class='h3'>
          <img src='../../assets/img/productVibration_2.png'>
        </div>
    </div>
    <!-- <div class="product-nav-wrap">
      <div class="product-nav">
        <a
          v-for="item in navList"
          :key="item.name"
          @click="navHandle(item.url)"
          >{{ item.name }}</a
        >
      </div>
    </div> -->
    <div class="survey" id="survey">
       <div
        class="energy-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        <img class='aiOPS_bjimg' src="../../assets/img/productSatellite_18.png" alt="" />
      </div>
      <div
        class="survey-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        产品简介
      </div>
      <p class='toptxt fadeOutUp'
        ref="animate14"
        :class="{ fadeInUp: animateData.animate14 }"
      >采用
        <span style='color:#414b59;font-size:20px'>“智能手机、IoT设备、云平台”</span>
        多端协同的设计思路，以全生命周期为主线，预防性维护为中心，兼顾设备档案、备品备件的管理，借助物联技术实现设备状态的实时监控与故障预警，利用数据和算法模型预测设备故障，帮助企业实现设备的规范化、科学化、智能化管理，降低设备故障率，保持设备稳定性，实现企业资产效益的全面提升。
      </p>
      <div class='topimgbox l-between fadeOutUp'
        ref="animate13"
        :class="{ fadeInUp: animateData.animate13 }"

      >

          <!-- <img class='topcen' src="../../assets/img/productSatellite_23.png" alt="" /> -->
           <img class=' topleftbg' src="../../assets/img/productSatellite_23.png" alt="" :class='{"fade1":istwo}'/>
          <img class=' topb topb1 tran' src="../../assets/img/productSatellite_22.png" alt="" :class='{"fade1":istwo}'/>
          <img class=' topb topb2 tran' src="../../assets/img/productSatellite_24.png" alt="" :class='{"fade1":istwo}'/>
          <img class=' topb topb3 tran' src="../../assets/img/productSatellite_27.png" alt="" :class='{"fade1":istwo}'/>
          <img class=' topb topb4 tran' src="../../assets/img/productSatellite_28.png" alt="" :class='{"fade1":istwo}'/>
          <img class=' topb topb5 tran' src="../../assets/img/productSatellite_29.png" alt="" :class='{"fade1":istwo}'/>


      </div>

    </div>
    <div class="features-wrap" id="features-wrap">
       <div
        class="energy-title fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
      >
        <img class='aiOPS_bjimg' src="../../assets/img/productSatellite_20.png" alt="" />
      </div>
      <div
        class="features-title  fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
      >
        核心能力
      </div>
      <div class="features-content l-wrap">
        <div
          class="features fadeOutUp"
          v-for="item in features"
          :key="item.name"
          :style="{
            transitionDelay: item.delay + 's',
          }"
          :class="{ fadeInUp: animateData[item.animate] }"
          :ref="item.animate"
        >
          <div class="features-icon">
            <img :src="item.icon" alt="" />
          </div>
          <div class="features-name">{{ item.name }}</div>
          <p class='features-txt'>{{item.txt}}</p>
        </div>
      </div>
    </div>
     <div class="scenes-wrap" id="scenes-wrap">
        <div
        class="energy-title fadeOutUp"
        ref="animate12"
        :class="{ fadeInUp: animateData.animate12 }"
      >
        <img class='aiOPS_bjimg' src="../../assets/img/productSatellite_19.png" alt="" />
      </div>
      <div
        class="scenes-title survey-title fadeOutUp"
        ref="animate12"
        :class="{ fadeInUp: animateData.animate12 }"
      >
        产品特点
      </div>

      <div class="scenes">
        <div
          class="cp-item fadeOutUp"
          v-for="item in scenes2"
          :key="item.name"
          :style="{
            backgroundImage: 'url(' + item.bg + ')',
            transitionDelay: item.delay + 's',
          }"
          :class="{ fadeInUp: animateData[item.animate] }"
          :ref="item.animate"
        >
         <div
        class='fadeOutUp'
         :class="{ fadeInUp: animateData[item.animate] }"
         :ref="item.animate"
          :style="{
            transitionDelay: (item.delay*1+0.3) + 's',
          }">
             <div class='cpimg'>
              <img :src="item.icon" alt="" />
            </div>
            <div class='cph3'>{{ item.name }}</div>
            <div class='cptxt'>{{ item.title }}</div>
        </div>
        </div>
      </div>
    </div>

    <div class="scenes-wrap" id="scenes-wrap">
        <div
        class="energy-title fadeOutUp"
        ref="animate2"
        :class="{ fadeInUp: animateData.animate2 }"
      >
        <img class='aiOPS_bjimg' src="../../assets/img/productSatellite_21.png" alt="" />
      </div>
      <div
        class="scenes-title survey-title fadeOutUp"
        ref="animate2"
        :class="{ fadeInUp: animateData.animate2 }"
      >
        应用场景
      </div>

      <div class="scenes" style='margin-bottom:80px'>
        <div
          class="scenes-item fadeOutUp"
          v-for="item in scenes"
          :key="item.name"
          :style="{
            transitionDelay: item.delay + 's',
          }"
          :class="{ fadeInUp: animateData[item.animate] }"
          :ref="item.animate"
        >
          <div style="height: 187px">
            <img :src="item.icon" alt="" />
          </div>
          <span class='yytxt'>{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isElementNotInViewport } from "../../utils/index.js";
export default {
  data() {
    return {
      istwo:true,
      navList: [
        {
          name: "产品概况",
          url: "survey",
        },
        {
          name: "产品特点",
          url: "features-wrap",
        },
        {
          name: "应用场景",
          url: "scenes-wrap",
        },
      ],
      product: [
        {
          name: "3D虚拟模型",
          icon: require("../../assets/images/product-icon_6.png"),
          animate: "animate3",
          delay: 0.1,
        },
        {
          name: "物联网感知",
          icon: require("../../assets/images/product-icon_7.png"),
          animate: "animate4",
          delay: 0.2,
        },
        {
          name: "三维可视化智能管控平台",
          icon: require("../../assets/images/product-icon_8.png"),
          animate: "animate5",
          delay: 0.3,
        },
      ],
      features: [
        {
          name: "全生命周期管理能力",
          icon: require("../../assets/img/productVibration_3.png"),
          bg: require("../../assets/images/features-bg_7.png"),
          animate: "animate6",
          txt:"支持设备资产购置、领用、转移、盘点、维修、报废全方位准确监管",
          delay: 0.1,
        },
        {
          name: "高效率维护保障能力",
          icon: require("../../assets/img/productVibration_4.png"),
          bg: require("../../assets/images/features-bg_8.png"),
          animate: "animate7",
          txt:"支持定期巡检、维修保养、工单处置、设备档案、备品配件精准管理",
          delay: 0.2,
        },
        {
          name: "智能化预测维护能力",
          icon: require("../../assets/img/productVibration_5.png"),
          bg: require("../../assets/images/features-bg_9.png"),
          animate: "animate8",
          txt:"支持IoT设备接入实时监测设备状态，借助算法和模型实现预测性维护",
          delay: 0.3,
        },
        {
          name: "精细化知识管理能力",
          icon: require("../../assets/img/productVibration_6.png"),
          bg: require("../../assets/images/features-bg_9.png"),
          animate: "animate8",
          txt:"支持设备资料、维保经验、规章制度、常用知识的多维度精细化管理",
          delay: 0.3,
        },
        {
          name: "多维度数据分析能力",
          icon: require("../../assets/img/productVibration_7.png"),
          bg: require("../../assets/images/features-bg_9.png"),
          animate: "animate8",
          txt:"支持自动化分析，实时掌握巡检状态、维修效率、工单执行、库存等",
          delay: 0.3,
        },
        {}
      ],
      scenes: [
        {
          name: "生产设备维保",
          icon: require("../../assets/img/productSatellite_15.png"),
          animate: "animate9",
          delay: 0.1,
        },
        {
          name: "工程机械管控",
          icon: require("../../assets/img/productSatellite_16.png"),
          animate: "animate10",
          delay: 0.2,
        },
        {
          name: "电气设备保障",
          icon: require("../../assets/img/productSatellite_17.png"),
          animate: "animate11",
          delay: 0.3,
        },
        {
          name: "医疗设备管理",
          icon: require("../../assets/img/productSatellite_26.png"),
          animate: "animate11",
          delay: 0.4,
        },
      ],
       scenes2: [
        {
          name: "多终端协同",
          title:"PC、Android、IOS、微信公众平台等多种终端数据互通，实现全平台协同管理",
          bg: require("../../assets/img/productSatellite_10.png"),
          icon: require("../../assets/img/productSatellite_5.png"),
          animate: "animate20",
          delay: 0.1,
        },
        {
          name: "多通知方式",
          title:"支持系统消息、邮件、短信、微信等多种通知方式，实现多渠道、多场景通知",
          bg: require("../../assets/img/productSatellite_11.png"),
          icon: require("../../assets/img/productSatellite_6.png"),
          animate: "animate21",
          delay: 0.2,
        },
        {
          name: "智能化辅助",
          title:"基于IoT数据、报警数据、规则数据，借助智能算法实现预测性维护和任务规划",
          bg: require("../../assets/img/productSatellite_12.png"),
          icon: require("../../assets/img/productSatellite_7.png"),
          animate: "animate22",
          delay: 0.3,
        },
        {
          name: "定制化交付",
          title:"全自主架构设计，支持与其他系统集成，支持二次开发定制，支持私有化部署",
          bg: require("../../assets/img/productSatellite_13.png"),
          icon: require("../../assets/img/productSatellite_8.png"),
          animate: "animate23",
          delay: 0.4,
        },
      ],

      animateData: {
        animate: false,
        animate1: false,
        animate2: false,
        animate3: false,
        animate4: false,
        animate5: false,
        animate6: false,
        animate7: false,
        animate8: false,
        animate9: false,
        animate10: false,
        animate11: false,

      },
    };
  },
  mounted() {
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {
    navHandle(id) {
      let target = document.getElementById(id);
      target.scrollIntoView();
    },
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? (this.animateData[i] = true)
          : (this.animateData[i] = false);


          if(i=='animate13' && !isElementNotInViewport(this.$refs[i])){
              setTimeout(()=>{
                this.istwo = false
              },800)

          }
           if(i=='animate13' && isElementNotInViewport(this.$refs[i])){
                this.istwo = true
          }
      }
    },
    scenesArrowHandle(val) {
      if (val == 1) {
        this.$refs.swiper.prev();
      } else {
        this.$refs.swiper.next();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
    width:100%;
    height:433px;
    background: url(../../assets/img/productSatellite_1.png) no-repeat center center;
    background-size:1920px 433px;
    .h3{
        position: relative;
        top:130px;
        width:1180px;
        margin: 0px auto;
    }
    h3{
        width: 578px;
        height: 45px;
        font-size: 34px;
        color: #FFFFFF;
        line-height: 45px;
        width:1180px;
        font-weight: 400;
        margin: 0px auto;
        position: relative;
        top:160px;
    }
    p{
        height: 19px;
        font-size: 14px;
        color: rgba(255, 255, 255, .49);
        line-height: 19px;
        width:1180px;
        margin: 0px auto;
        position: relative;
        top:182px;

    }
}

.scenes {
  margin-top: 38px;

  .scenes-item {
    width: 290px;
    box-shadow: 0px 2px 14px 9px rgba(77, 77, 77, 0.07);

    img {
      display: block;
    }

    div {
      height: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: #243159;
    }
  }
}
.energy-title{
  text-align: center;
}
.survey-title{
  height: 37px;
  font-size: 28px;
  color: #414B59;
  line-height: 37px;
  position: relative;
  top:-23px;
}
.features-title{
  font-size: 28px;
  color: #FFFFFF;
  line-height: 37px;
  position: relative;
  top:-23px;
}
.features-wrap{
  height: 809px;
  background: #3E76E0;

}
.features{
  padding:0;
  margin-top:25px;
  .features-icon{

  }
  .features-name{
    margin-top: 16px !important;
    height: 48px;
    color: #FFFFFF;
  }
  .features-txt{
    height: 90px;
    font-size: 14px;
    color: rgba(255, 255, 255, .66);
    line-height: 30px;
    width:284px;
    margin:0 auto;
  }
}
.yytxt{
  font-size: 18px !important;
  color: #909399 !important;
  height:67px !important;
}
.toptxt{
  width: 1155px;
  height: 96px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #909399;
  line-height: 32px;
  margin:0 auto;
  text-align: center;
  margin-top:10px;
}
.topimgbox{
  height:620px;
  width:1139px;
  margin:20px auto;
  // background: url(../../assets/img/productSatellite_23.png) no-repeat center center;
  // background-size:1521px 992px;
  .topleftbg{
      width:1521px;
      height: 992px;
      position:absolute;
      top:-200px;
      left:50%;
      margin-left:-760px;
    }
  .topb{
    text-align: center;
    position: absolute;
    opacity: 1;
    p{
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #4481DD;
      position: relative;
      top:-5px;
    }
  }
  .topb1{
    top:10px;
    left:50%;
    margin-left:-301px;
  }
  .topb2{
    top:185px;
    left:30px;
  }
  .topb3{
    top:185px;
    right:38px;
  }
  .topb4{
    top:438px;
    left:105px;
  }
  .topb5{
    top:438px;
    right:113px;
  }
  .fade1{
    opacity: 0;

  }
  .topb1.fade1{top:40%;}
  .topb2.fade1{left:30%;}
  .topb3.fade1{right:30%;}
  .topb4.fade1{top:200px;left:30%;}
  .topb5.fade1{top:200px;right:30%;}
  .fade2{
    opacity: 0;
    left:-100px;

  }
  .topleft{

  }
  .toprig{

  }
}

.cp-item{
  width:288px;
  height:378px;
  text-align: center;
  padding-top:98px;
  background:#396BE3;
  .cpimg{

  }
  .cph3{
    height: 30px;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 24px;
    margin:20px 0 7px 0;

  }
  .cptxt{
      width: 214px;
      height: 48px;
      font-size: 14px;
      color: rgba(255, 255, 255, .9);
      line-height: 24px;
      margin: 0 auto;
  }
}
.cp-item:hover{
    background:#396BE3;
    background-image:none !important;
  }
  .tran{
    transition: all .7s ease-in;
    -webkit-transition:all .7s ease-in;
  }
</style>
