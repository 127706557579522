<template>
  <div>
    <div class="banner">
     <div  class='h3'>
          <img src='../../assets/img/productRobot_18.png'>
        </div>
    </div>
    <!-- <div class="product-nav-wrap">
      <div class="product-nav">
        <a
          v-for="item in navList"
          :key="item.name"
          @click="navHandle(item.url)"
          >{{ item.name }}</a
        >
      </div>
    </div> -->
    <div class="survey" id="survey">
       <div
        class="energy-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        <img class='aiOPS_bjimg' src="../../assets/img/productSatellite_18.png" alt="" />
      </div>
      <div
        class="survey-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        产品简介
      </div>
       <p class='toptxt fadeOutUp'
        ref="animate13"
        :class="{ fadeInUp: animateData.animate13 }"
        >采用<span>“获客+营销+交易+客服”</span>的客户全生命周期管理设计思路，以客户为中心分别打造连接客户、合作伙伴以及内部员工的“线索管理”、“客户管理”、“商机管理”、“产品管理”、“合同管理”、“营收管理”、“来电工作台”、“在线客服”等核心能力，以此为企业提供一站式全业务协作和运营的客户管理解决方案。 </p>
      <div class='topbox fadeOutUp '
         ref="animate14"
        :class="{ fadeInUp: animateData.animate14 , topbox2:istwo  }"
      >

            <div class='topleft'>

              <img class='topleftbg ' src="../../assets/img/productHSE_2.png" alt="" />
                <img class='topleftimg1 tran' src="../../assets/img/productHSE_3.png" alt="" />
                <img class='topleftimg2 tran' src="../../assets/img/productHSE_4.png" alt="" />
                <img class='topleftimg3 tran' src="../../assets/img/productHSE_5.png" alt="" />
                <img class='topleftimg4 tran' src="../../assets/img/productHSE_21.png" alt="" />

            </div>


      </div>

    </div>
    <div class="features-wrap" id="features-wrap">
       <div
        class="energy-title fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
      >
        <img class='aiOPS_bjimg' src="../../assets/img/productSatellite_20.png" alt="" />
      </div>
      <div
        class="features-title  fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
      >
        核心能力
      </div>
      <div class="features-content">
        <div
          class="features fadeOutUp"
          v-for="item in features"
          :key="item.name"
          :style="{
            transitionDelay: item.delay + 's',
          }"
          :class="{ fadeInUp: animateData[item.animate] }"
          :ref="item.animate"
        >
          <div class="features-icon">
            <img :src="item.icon" alt="" />
          </div>
          <div class="features-name">{{ item.name }}</div>
          <p class='features-txt'>{{item.txt}}</p>
        </div>
      </div>
    </div>
     <div class="scenes-wrap" id="scenes-wrap">
        <div
        class="energy-title fadeOutUp"
        ref="animate12"
        :class="{ fadeInUp: animateData.animate12 }"
      >
        <img class='aiOPS_bjimg' src="../../assets/img/productSatellite_19.png" alt="" />
      </div>
      <div
        class="scenes-title survey-title fadeOutUp"
        ref="animate12"
        :class="{ fadeInUp: animateData.animate12 }"
      >
        产品特点
      </div>

      <div class="scenes">
        <div
          class="cp-item fadeOutUp"
          v-for="item in scenes2"
          :key="item.name"
          :style="{
            backgroundImage: 'url(' + item.bg + ')',
            transitionDelay: item.delay + 's',
          }"
          :class="{ fadeInUp: animateData[item.animate] }"
          :ref="item.animate"
        >
         <div
        class='fadeOutUp'
         :class="{ fadeInUp: animateData[item.animate] }"
         :ref="item.animate"
          :style="{
            transitionDelay: (item.delay*1+0.3) + 's',
          }">
             <div class='cpimg'>
              <img :src="item.icon" alt="" />
            </div>
            <div class='cph3'>{{ item.name }}</div>
            <div class='cptxt'>{{ item.title }}</div>
        </div>
        </div>
      </div>
    </div>

    <div class="scenes-wrap" id="scenes-wrap">
        <div
        class="energy-title fadeOutUp"
        ref="animate2"
        :class="{ fadeInUp: animateData.animate2 }"
      >
        <img class='aiOPS_bjimg' src="../../assets/img/productSatellite_21.png" alt="" />
      </div>
      <div
        class="scenes-title survey-title fadeOutUp"
        ref="animate2"
        :class="{ fadeInUp: animateData.animate2 }"
      >
        应用场景
      </div>

      <div class="scenes  l-wrap" style='margin-bottom:80px'>
        <div
          class="scenes-item fadeOutUp"
          v-for="item in scenes"
          :key="item.name"
          :style="{
            transitionDelay: item.delay + 's',
          }"
          :class="{ fadeInUp: animateData[item.animate] }"
          :ref="item.animate"
        >
          <div style="height: 250px">
            <img :src="item.icon" alt="" />
          </div>
          <span class='yytxt'>{{ item.name }}</span>
        </div>
      </div>



    </div>

  </div>
</template>

<script>
import { isElementNotInViewport } from "../../utils/index.js";
export default {
  data() {
    return {
      istwo:true,
      navList: [
        {
          name: "产品概况",
          url: "survey",
        },
        {
          name: "产品特点",
          url: "features-wrap",
        },
        {
          name: "应用场景",
          url: "scenes-wrap",
        },
      ],
      product: [
        {
          name: "3D虚拟模型",
          icon: require("../../assets/images/product-icon_6.png"),
          animate: "animate3",
          delay: 0.1,
        },
        {
          name: "物联网感知",
          icon: require("../../assets/images/product-icon_7.png"),
          animate: "animate4",
          delay: 0.2,
        },
        {
          name: "三维可视化智能管控平台",
          icon: require("../../assets/images/product-icon_8.png"),
          animate: "animate5",
          delay: 0.3,
        },
      ],
      features: [
        {
          name: "一站式全业务协作能力",
          icon: require("../../assets/img/productHSE_6.png"),
          bg: require("../../assets/images/features-bg_7.png"),
          animate: "animate6",
          txt:"获客+营销+交易，实现客户、合作伙伴、内部员工的全流程管理",
          delay: 0.1,
        },
        {
          name: "面向企业的客户化能力",
          icon: require("../../assets/img/productHSE_22.png"),
          bg: require("../../assets/images/features-bg_8.png"),
          animate: "animate7",
          txt:"定制化开发+私有化部署，全面满足企业的个性化需求",
          delay: 0.2,
        },
        {
          name: "客户服务融合通信能力",
          icon: require("../../assets/img/productHSE_8.png"),
          bg: require("../../assets/images/features-bg_9.png"),
          animate: "animate8",
          txt:"话务通讯+即时消息，融合接入大幅提高服务效率和用户体验",
          delay: 0.3,
        },
        {
          name: "多系统对接和集成能力",
          icon: require("../../assets/img/productHSE_81.png"),
          bg: require("../../assets/images/features-bg_9.png"),
          animate: "animate8",
          txt:"支持OA+ERP+呼叫中心+客服等多种系统接口对接",
          delay: 0.3,
        },
      ],
      scenes: [
        {
          name: "生产制造型企业",
          icon: require("../../assets/img/productHSE_15.png"),
          animate: "animate15",
          delay: 0.1,
        },
        {
          name: "软件研发型企业",
          icon: require("../../assets/img/productHSE_16.png"),
          animate: "animate16",
          delay: 0.2,
        },
        {
          name: "技术服务型企业",
          icon: require("../../assets/img/productHSE_17.png"),
          animate: "animate17",
          delay: 0.3,
        },
        //  {
        //   name: "风电设备检修",
        //   icon: require("../../assets/img/productHSE_18.png"),
        //   animate: "animate18",
        //   delay: 0.4,
        // },
        //  {
        //   name: "建筑工程监理",
        //   icon: require("../../assets/img/productHSE_19.png"),
        //   animate: "animate19",
        //   delay: 0.5,
        // },
        //  {
        //   name: "通信设施建设",
        //   icon: require("../../assets/img/productHSE_20.png"),
        //   animate: "animate40",
        //   delay: 0.6,
        // },

      ],
      scenes3: [

      ],
       scenes2: [
        {
          name: "全生命周期管理体系",
          title:"“获客、营销、交易、客服”的全生命周期客户管理",
          bg: require("../../assets/img/productHSE_12.png"),
          icon: require("../../assets/img/productHSE_9.png"),
          animate: "animate20",
          delay: 0.1,
        },
        {
          name: "连接全员赋能销售",
          title:"以全生命周期客户管理为核心，连接多部门协作支撑",
          bg: require("../../assets/img/productHSE_13.png"),
          icon: require("../../assets/img/productHSE_10.png"),
          animate: "animate21",
          delay: 0.2,
        },
        {
          name: "客户化系统整体交付",
          title:"可根据客户需求，实现定制化开发和私有化部署",
          bg: require("../../assets/img/productHSE_14.png"),
          icon: require("../../assets/img/productHSE_11.png"),
          animate: "animate22",
          delay: 0.3,
        },

      ],

      animateData: {
        animate: false,
        animate1: false,
        animate2: false,
        animate3: false,
        animate4: false,
        animate5: false,
        animate6: false,
        animate7: false,
        animate8: false,
        animate9: false,
        animate10: false,
        animate11: false,
        animate15: false,
        animate16: false,
        animate17: false,
        animate18: false,
        animate19: false,
        animate40: false,

      },
    };
  },
  mounted() {
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {
    navHandle(id) {
      let target = document.getElementById(id);
      target.scrollIntoView();
    },
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? (this.animateData[i] = true)
          : (this.animateData[i] = false);

          if(i=='animate14' && !isElementNotInViewport(this.$refs[i])){
              setTimeout(()=>{
                this.istwo = false

              },700)
          }
          if(i=='animate14' && isElementNotInViewport(this.$refs[i])){
                this.istwo = true
          }

      }
    },
    scenesArrowHandle(val) {
      if (val == 1) {
        this.$refs.swiper.prev();
      } else {
        this.$refs.swiper.next();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
    width:100%;
    height:433px;
    background: url(../../assets/img/productRobot_1.png) no-repeat center center;
    background-size:1920px 433px;
    .h3{
        position: relative;
        top:130px;
        width:1180px;
        margin: 0px auto;
    }
    h3{
        width: 578px;
        height: 45px;
        font-size: 34px;
        color: #FFFFFF;
        line-height: 45px;
        width:1180px;
        font-weight: 400;
        margin: 0px auto;
        position: relative;
        top:160px;
    }
    p{
        height: 19px;
        font-size: 14px;
        color: rgba(255, 255, 255, .49);
        line-height: 19px;
        width:1180px;
        margin: 0px auto;
        position: relative;
        top:182px;

    }
}

.scenes {
  margin-top: 38px;


  .scenes-item {
    width: 387px;
    box-shadow: 0px 2px 14px 9px rgba(77, 77, 77, 0.07);
    margin-bottom:19px;

    img {
      display: block;
    }

    div {
      height: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: #243159;
    }
  }
}
.scenesbox{

}
.energy-title{
  text-align: center;
}
.survey-title{
  height: 37px;
  font-size: 28px;
  color: #414B59;
  line-height: 37px;
  position: relative;
  top:-23px;
}
.features-title{
  font-size: 28px;
  color: #FFFFFF;
  line-height: 37px;
  position: relative;
  top:-23px;
}
.features-wrap{
  height: 479px;
  background: #3E76E0;

}
.features{
  width:284px !important;
  padding:0;
  .features-icon{

  }
  .features-name{
    margin-top: 16px !important;
    height: 48px;
    color: #FFFFFF;
  }
  .features-txt{
    height: 90px;
    font-size: 14px;
    color: rgba(255, 255, 255, .66);
    line-height: 30px;
    width:220px;
    margin:0 auto;
  }
}
.yytxt{
  font-size: 18px !important;
  color: #909399 !important;
}
.toptxt{
     width: 1113px;
    height: 128px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #909399;
    line-height: 32px;
    margin:0 auto;
    margin-top:10px;
    text-align: center ;
    span{
        color:rgba(65, 75, 89, 1);
        font-size:20px;
    }
}
.cp-item{
  width:384px;
  height:508px;
  text-align: center;
  padding-top:160px;
  background:#396BE3;
  .cpimg{

  }
  .cph3{
    height: 30px;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 24px;
    margin:26px 0 7px 0;

  }
  .cptxt{
      width: 350px;
      height: 48px;
      font-size: 14px;
      color: rgba(255, 255, 255, .9);
      line-height: 24px;
      margin: 0 auto;
  }
}
.cp-item:hover{
    background:#396BE3;
    background-image:none !important;
  }
// 产品简介图
.topbox{
    width:1092px;
    margin:0 auto;
    margin-top:20px;
    margin-bottom:50px;
    .topleft{
        width: 100%;
        height: 600px;
        // background: url(../../assets/img/productHSE_2.png) no-repeat center center;
        // background-size:1521px 992px;
        position: relative;
        margin:0 auto;
        margin-top:30px;
        .topleftbg{
          width:1521px;
          height: 992px;
          position:absolute;
          top:-200px;
          left:50%;
          margin-left:-760px;
        }
        img{
            position: absolute;
        }
        .topleftimg1{
            top:-20px;
            left:50%;
            margin-left:-244px;
        }
        .topleftimg2{
            top:510px;
            left:50%;
            margin-left:-244px;
        }
        .topleftimg3{
            top:50%;
            margin-top:-151px;
            left:110px;
        }
        .topleftimg4{
            top:50%;
            margin-top:-151px;
            right:110px;
        }
    }
}
.topbox2{
    .topleft{
        .topleftimg1{
            top:210px;
            left: 50%;
            margin-left: -244px;
            opacity: 0;
        }
        .topleftimg2{
            top: 280px;
            left: 50%;
            margin-left: -244px;
            opacity: 0;
        }
        .topleftimg3{
            left:410px;
            opacity: 0;
        }
        .topleftimg4{
            right:410px;
            opacity: 0;
        }
    }
}
.tran{
    transition: all 1s ease-in;
    -webkit-transition:all 1s ease-in;
  }
</style>
