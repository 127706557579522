<template>
  <div>
    <div class="banner">
      <img src="../../assets/images/product-banner_1.png" alt="" />
    </div>
    <div class="product-nav-wrap">
      <div class="product-nav">
        <a v-for="item in navList" :key="item.name" @click="navHandle(item.url)">{{
          item.name
        }}</a>
      </div>
    </div>
    <div class="survey" id="survey">
      <div
        class="survey-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        产品概况
      </div>
      <div class="survey-content">
        <div
          class="product fadeOutUp"
          v-for="item in product"
          :key="item.name"
          :class="{ fadeInUp: animateData[item.animate] }"
          :style="{
            transitionDelay: item.delay + 's',
          }"
          :ref="item.animate"
        >
          <img :src="item.icon" alt="" />
          <span>{{ item.name }}</span>
        </div>
        <div class="dotted"></div>
        <img
          class="survey-left"
          src="../../assets/images/survey-left.png"
          alt=""
        />
        <img
          class="survey-right"
          src="../../assets/images/survey-right.png"
          alt=""
        />
      </div>
      <div class="survey-bottom">
        <img src="../../assets/images/product-img_1.png" alt="" />
        <div>机器人智能巡<br />检解决方案</div>
      </div>
    </div>
    <div class="features-wrap" id="features-wrap">
      <div
        class="features-title fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
      >
        产品特点
      </div>
      <div class="features-content">
        <div
          class="features fadeOutUp"
          v-for="item in features"
          :key="item.name"
          :style="{
            backgroundImage: 'url(' + item.bg + ')',
            transitionDelay: item.delay + 's',
          }"
          :ref="item.animate"
          :class="{ fadeInUp: animateData[item.animate] }"
        >
          <div class="features-icon">
            <img :src="item.icon" alt="" />
          </div>
          <div class="features-name">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="scenes-wrap" id="scenes-wrap">
      <div
        class="scenes-title fadeOutUp"
        ref="animate2"
        :class="{ fadeInUp: animateData.animate2 }"
      >
        应用场景
      </div>
      <el-carousel
        type="card"
        height="622px"
        indicator-position="none"
        ref="swiper"
      >
        <img
          class="scenes-arrow-left"
          @click="scenesArrowHandle(1)"
          src="../../assets/images/scenes-arrow.png"
          alt=""
        />
        <img
          class="scenes-arrow-right"
          @click="scenesArrowHandle(2)"
          src="../../assets/images/scenes-arrow.png"
          alt=""
        />
        <el-carousel-item v-for="item in scenes" :key="item.name">
          <div class="scenes-name">{{ item.name }}</div>
          <div class="scenes-tip">{{ item.tip }}</div>
          <div class="rebot">
            <img
              :src="item.icon"
              :style="{ width: item.width + 'px' }"
              alt=""
            />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import { isElementNotInViewport, throttle } from "../../utils/index.js";
export default {
  data() {
    return {
      navList: [
        {
          name: "产品概况",
          url: "survey",
        },
        {
          name: "产品特点",
          url: "features-wrap",
        },
        {
          name: "应用场景",
          url: "scenes-wrap",
        },
      ],
      product: [
        {
          name: "检测机器人",
          icon: require("../../assets/images/product-icon_1.png"),
          animate: "animate6",
          delay: 0.2,
        },
        {
          name: "智能运检管理平台",
          icon: require("../../assets/images/product-icon_2.png"),
          animate: "animate7",
          delay: 0.3,
        },
      ],
      features: [
        {
          name: "多场景应用",
          icon: require("../../assets/images/features_1.png"),
          bg: require("../../assets/images/features-bg_1.png"),
          animate: "animate3",
          delay: 0.1,
        },
        {
          name: "全天候支撑",
          icon: require("../../assets/images/features_3.png"),
          bg: require("../../assets/images/features-bg_2.png"),
          animate: "animate4",
          delay: 0.2,
        },
        {
          name: "高效率执行",
          icon: require("../../assets/images/features_2.png"),
          bg: require("../../assets/images/features-bg_3.png"),
          animate: "animate5",
          delay: 0.3,
        },
      ],
      scenes: [
        {
          name: "绝缘油取样",
          tip: "取样机器人",
          icon: require("../../assets/images/rebot_2.png"),
          width: 230,
        },
        {
          name: "变压器巡检",
          tip: "油中机器人",
          icon: require("../../assets/images/rebot_3.png"),
          width: 258,
        },
        {
          name: "水电站坝体检测",
          tip: "水下机器人",
          icon: require("../../assets/images/rebot_4.png"),
          width: 250,
        },
        {
          name: "隧洞巡检",
          tip: "巡检机器人",
          icon: require("../../assets/images/rebot_1.png"),
          width: 242,
        },
      ],
      animateData: {
        animate: false,
        animate1: false,
        animate2: false,
        animate3: false,
        animate4: false,
        animate5: false,
        animate6: false,
        animate7: false,
      },
    };
  },
  mounted() {
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {
    navHandle(id) {
      let target = document.getElementById(id);
      target.scrollIntoView();
    },
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        if (i != "swiper") {
          !isElementNotInViewport(this.$refs[i])
            ? (this.animateData[i] = true)
            : (this.animateData[i] = false);
        }
      }
    },
    scenesArrowHandle(val) {
      if (val == 1) {
        this.$refs.swiper.prev();
      } else {
        this.$refs.swiper.next();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  img {
    width: 100%;
    display: block;
  }
}
</style>