<template>
  <div>
    <div class="banner">
      <img src="../../assets/images/product-banner_6.png" alt="" />
    </div>
    <div class="product-nav-wrap">
      <div class="product-nav">
        <a
          v-for="item in navList"
          :key="item.name"
          @click="navHandle(item.url)"
          >{{ item.name }}</a
        >
      </div>
    </div>
    <div class="survey" id="survey">
      <div
        class="survey-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        产品概况
      </div>
      <div class="survey-content">
        <div
          class="product fadeOutUp"
          v-for="item in product"
          :key="item.name"
          :class="{ fadeInUp: animateData[item.animate] }"
          :style="{
            transitionDelay: item.delay + 's',
          }"
          :ref="item.animate"
        >
          <img :src="item.icon" alt="" />
          <span>{{ item.name }}</span>
        </div>
        <div class="dotted"></div>
        <img
          class="survey-left"
          src="../../assets/images/survey-left.png"
          alt=""
        />
        <img
          class="survey-right"
          src="../../assets/images/survey-right.png"
          alt=""
        />
      </div>
      <div class="survey-bottom">
        <img src="../../assets/images/product-img_6.png" alt="" />
        <div>无人机缺陷自动<br />识别解决方案</div>
      </div>
    </div>
    <div class="features-wrap" id="features-wrap">
      <div
        class="features-title fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
      >
        产品特点
      </div>
      <div class="features-content">
        <div
          class="features fadeOutUp"
          v-for="item in features"
          :key="item.name"
          :style="{
            backgroundImage: 'url(' + item.bg + ')',
            transitionDelay: item.delay + 's',
          }"
          :class="{ fadeInUp: animateData[item.animate] }"
          :ref="item.animate"
        >
          <div class="features-icon">
            <img :src="item.icon" alt="" />
          </div>
          <div class="features-name">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="scenes-wrap" id="scenes-wrap">
      <div
        class="scenes-title fadeOutUp"
        ref="animate2"
        :class="{ fadeInUp: animateData.animate2 }"
      >
        应用场景
      </div>
      <div class="scenes">
        <div
          class="scenes-item fadeOutUp"
          v-for="item in scenes"
          :key="item.name"
          :style="{
            transitionDelay: item.delay + 's',
          }"
          :class="{ fadeInUp: animateData[item.animate] }"
          :ref="item.animate"
        >
          <div>
            <img :src="item.icon" alt="" />
          </div>
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isElementNotInViewport } from "../../utils/index.js";

export default {
  data() {
    return {
      navList: [
        {
          name: "产品概况",
          url: "survey",
        },
        {
          name: "产品特点",
          url: "features-wrap",
        },
        {
          name: "应用场景",
          url: "scenes-wrap",
        },
      ],
      product: [
        {
          name: "无人机",
          icon: require("../../assets/images/product-icon_17.png"),
          animate: "animate3",
          delay: 0.1,
        },
        {
          name: "AI图像分析平台",
          icon: require("../../assets/images/product-icon_16.png"),
          animate: "animate4",
          delay: 0.2,
        },
      ],
      features: [
        {
          name: "覆盖范围广",
          icon: require("../../assets/images/features_16.png"),
          bg: require("../../assets/images/features-bg_16.png"),
          animate: "animate5",
          delay: 0.1,
        },
        {
          name: "作业效率高",
          icon: require("../../assets/images/features_17.png"),
          bg: require("../../assets/images/features-bg_17.png"),
          animate: "animate6",
          delay: 0.2,
        },
        {
          name: "缺陷识别快",
          icon: require("../../assets/images/features_18.png"),
          bg: require("../../assets/images/features-bg_18.png"),
          animate: "animate7",
          delay: 0.3,
        },
      ],
      scenes: [
        {
          name: "输电线路无人机巡检",
          icon: require("../../assets/images/scenes_14.png"),
          animate: "animate8",
          delay: 0.1,
        },
        {
          name: "输变电设备红外图像识别",
          icon: require("../../assets/images/scenes_15.png"),
          animate: "animate9",
          delay: 0.2,
        },
        {
          name: "输变电设备缺陷自动识别",
          icon: require("../../assets/images/scenes_16.png"),
          animate: "animate10",
          delay: 0.3,
        },
      ],
      animateData: {
        animate: false,
        animate1: false,
        animate2: false,
      },
    };
  },
  mounted() {
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {
    navHandle(id) {
      let target = document.getElementById(id);
      target.scrollIntoView();
    },
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? (this.animateData[i] = true)
          : (this.animateData[i] = false);
      }
    },
    scenesArrowHandle(val) {
      if (val == 1) {
        this.$refs.swiper.prev();
      } else {
        this.$refs.swiper.next();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  img {
    width: 100%;
    display: block;
  }
}
.scenes {
  margin-top: 30px;
  .scenes-item {
    width: 386px;
  }
}
</style>