<template>
  <div>
    <div class="banner">
      <img src="../../assets/images/product-banner_3.png" alt="" />
    </div>
    <div class="product-nav-wrap">
      <div class="product-nav">
        <a
          v-for="item in navList"
          :key="item.name"
          @click="navHandle(item.url)"
          >{{ item.name }}</a
        >
      </div>
    </div>
    <div class="survey" id="survey">
      <div
        class="survey-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        产品概况
      </div>
      <div class="survey-content">
        <div
          class="product fadeOutUp"
          v-for="item in product"
          :key="item.name"
          :class="{ fadeInUp: animateData[item.animate] }"
          :style="{
            transitionDelay: item.delay + 's',
          }"
          :ref="item.animate"
        >
          <img :src="item.icon" alt="" />
          <span>{{ item.name }}</span>
        </div>
        <div class="dotted"></div>
        <img
          class="survey-left"
          src="../../assets/images/survey-left.png"
          alt=""
        />
        <img
          class="survey-right"
          src="../../assets/images/survey-right.png"
          alt=""
        />
      </div>
      <div class="survey-bottom">
        <img src="../../assets/images/product-img_3.png" alt="" />
        <div>数字孪生智慧变<br />电站解决方案</div>
      </div>
    </div>
    <div class="features-wrap" id="features-wrap">
      <div
        class="features-title fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
      >
        产品特点
      </div>
      <div class="features-content">
        <div
          class="features fadeOutUp"
          v-for="item in features"
          :key="item.name"
          :style="{
            backgroundImage: 'url(' + item.bg + ')',
            transitionDelay: item.delay + 's',
          }"
          :class="{ fadeInUp: animateData[item.animate] }"
          :ref="item.animate"
        >
          <div class="features-icon">
            <img :src="item.icon" alt="" />
          </div>
          <div class="features-name">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="scenes-wrap" id="scenes-wrap">
      <div
        class="scenes-title fadeOutUp"
        ref="animate2"
        :class="{ fadeInUp: animateData.animate2 }"
      >
        应用场景
      </div>
      <div
        class="scenes-subtitle fadeOutUp"
        ref="animate12"
        :class="{ fadeInUp: animateData.animate12 }"
        style="transition-delay: 0.2s"
      >
        数字孪生智慧变电站
      </div>
      <div class="scenes">
        <div
          class="scenes-item fadeOutUp"
          v-for="item in scenes"
          :key="item.name"
          :style="{
            transitionDelay: item.delay + 's',
          }"
          :class="{ fadeInUp: animateData[item.animate] }"
          :ref="item.animate"
        >
          <div style="height: 250px">
            <img :src="item.icon" alt="" />
          </div>
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isElementNotInViewport } from "../../utils/index.js";
export default {
  data() {
    return {
      navList: [
        {
          name: "产品概况",
          url: "survey",
        },
        {
          name: "产品特点",
          url: "features-wrap",
        },
        {
          name: "应用场景",
          url: "scenes-wrap",
        },
      ],
      product: [
        {
          name: "3D虚拟模型",
          icon: require("../../assets/images/product-icon_6.png"),
          animate: "animate3",
          delay: 0.1,
        },
        {
          name: "物联网感知",
          icon: require("../../assets/images/product-icon_7.png"),
          animate: "animate4",
          delay: 0.2,
        },
        {
          name: "三维可视化智能管控平台",
          icon: require("../../assets/images/product-icon_8.png"),
          animate: "animate5",
          delay: 0.3,
        },
      ],
      features: [
        {
          name: "虚拟化",
          icon: require("../../assets/images/features_7.png"),
          bg: require("../../assets/images/features-bg_7.png"),
          animate: "animate6",
          delay: 0.1,
        },
        {
          name: "数字化",
          icon: require("../../assets/images/features_8.png"),
          bg: require("../../assets/images/features-bg_8.png"),
          animate: "animate7",
          delay: 0.2,
        },
        {
          name: "智能化",
          icon: require("../../assets/images/features_9.png"),
          bg: require("../../assets/images/features-bg_9.png"),
          animate: "animate8",
          delay: 0.3,
        },
      ],
      scenes: [
        {
          name: "三维变电站",
          icon: require("../../assets/images/scenes_3.png"),
          animate: "animate9",
          delay: 0.1,
        },
        {
          name: "人员定位",
          icon: require("../../assets/images/scenes_4.png"),
          animate: "animate10",
          delay: 0.2,
        },
        {
          name: "机器人巡检",
          icon: require("../../assets/images/scenes_5.png"),
          animate: "animate11",
          delay: 0.3,
        },
      ],

      animateData: {
        animate: false,
        animate1: false,
        animate2: false,
      },
    };
  },
  mounted() {
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {
    navHandle(id) {
      let target = document.getElementById(id);
      target.scrollIntoView();
    },
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? (this.animateData[i] = true)
          : (this.animateData[i] = false);
      }
    },
    scenesArrowHandle(val) {
      if (val == 1) {
        this.$refs.swiper.prev();
      } else {
        this.$refs.swiper.next();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  img {
    width: 100%;
    display: block;
  }
}

.scenes {
  margin-top: 38px;

  .scenes-item {
    width: 387px;
    box-shadow: 0px 2px 14px 9px rgba(77, 77, 77, 0.07);

    img {
      display: block;
    }

    div {
      height: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: #243159;
    }
  }
}
</style>