<template>
  <div>
    <div class="banner">
      <img src="../../assets/images/hat-banner_4.png" alt="" />
    </div>
    <div class="product-nav-wrap">
      <div class="product-nav">
        <a
          v-for="item in navList"
          :key="item.name"
          @click="navHandle(item.url)"
          >{{ item.name }}</a
        >
      </div>
    </div>
    <div class="survey" id="survey">
      <div
        class="survey-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        产品概况
      </div>
      <div class="survey-content">
        <div
          class="product fadeOutUp"
          v-for="item in product"
          :key="item.name"
          :class="{ fadeInUp: animateData[item.animate] }"
          :style="{
            transitionDelay: item.delay + 's',
          }"
          :ref="item.animate"
        >
          <img :src="item.icon" alt="" />
          <span>{{ item.name }}</span>
        </div>
        <div class="dotted"></div>
        <img
          class="survey-left"
          src="../../assets/images/survey-left.png"
          alt=""
        />
        <img
          class="survey-right"
          src="../../assets/images/survey-right.png"
          alt=""
        />
      </div>
      <div class="survey-bottom">
        <img src="../../assets/images/hat-img_1.png" alt="" />
        <div>
          基于HSE管理体系的作业人员<br>安全管控解决方案
        </div>
      </div>
    </div>
    <div class="features-wrap" id="features-wrap">
      <div
        class="features-title fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
      >
        产品特点
      </div>
      <div class="features-content">
        <div
          class="features fadeOutUp"
          v-for="item in features"
          :key="item.name"
          :style="{
            backgroundImage: 'url(' + item.bg + ')',
            transitionDelay: item.delay + 's',
          }"
          :class="{ fadeInUp: animateData[item.animate] }"
          :ref="item.animate"
        >
          <div class="features-icon">
            <img :src="item.icon" alt="" />
          </div>
          <div class="features-name">{{ item.name }}</div>
          <div style="font-size:14px;color:rgba(255,255,255,.8);margin-top:10px">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <div class="scenes-wrap" id="scenes-wrap">
      <div
        class="scenes-title fadeOutUp"
        ref="animate2"
        :class="{ fadeInUp: animateData.animate2 }"
      >
        应用场景
      </div>
      <div
        class="scenes-subtitle fadeOutUp"
        ref="animate14"
        :class="{ fadeInUp: animateData.animate14 }"
        style="transition-delay: 0.2s"
      >
        作业人员安全管控
      </div>
      <div class="scenes">
        <div
          class="scenes-item fadeOutUp"
          v-for="item in scenes"
          :key="item.name"
          :style="{
            transitionDelay: item.delay + 's',
          }"
          :class="{ fadeInUp: animateData[item.animate] }"
          :ref="item.animate"
        >
          <div>
            <img :src="item.icon" alt="" />
          </div>
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isElementNotInViewport } from "../../utils/index.js";

export default {
  data() {
    return {
      navList: [
        {
          name: "产品概况",
          url: "survey",
        },
        {
          name: "产品特点",
          url: "features-wrap",
        },
        {
          name: "应用场景",
          url: "scenes-wrap",
        },
      ],
      product: [
        {
          name: "智能安全帽",
          icon: require("../../assets/images/hat-icon_1.png"),
          animate: "animate3",
          delay: 0.1,
        },
        {
          name: "安全管控平台",
          icon: require("../../assets/images/hat-icon_3.png"),
          animate: "animate5",
          delay: 0.3,
        },
        {
          name: "健康保障服务",
          icon: require("../../assets/images/hat-icon_2.png"),
          animate: "animate4",
          delay: 0.2,
        },
      ],
      features: [
        {
          name: "先进的管理体系",
          text: '“人身健康、作业环境、安全管控”的多维保障',
          icon: require("../../assets/images/hat_10.png"),
          bg: require("../../assets/images/hat-bg_10.png"),
          animate: "animate6",
          delay: 0.1,
        },
        {
          name: "全周期的服务覆盖",
          text: '“作业前、作业中、作业后”全生命周期的精准保障',
          icon: require("../../assets/images/hat_11.png"),
          bg: require("../../assets/images/hat-bg_11.png"),
          animate: "animate7",
          delay: 0.2,
        },
        {
          name: "全面化的服务组合",
          text: '“智能安全帽+科学管控+多层健康服务“的全面保障',
          icon: require("../../assets/images/hat_12.png"),
          bg: require("../../assets/images/hat-bg_12.png"),
          animate: "animate8",
          delay: 0.3,
        },
      ],
      scenes: [
        {
          name: "输电线路巡检",
          icon: require("../../assets/images/hat_1.png"),
          animate: "animate9",
          delay: 0.1,
        },
        {
          name: "地铁线路运维",
          icon: require("../../assets/images/hat_2.png"),
          animate: "animate10",
          delay: 0.2,
        },
        {
          name: "铁路交通检修",
          icon: require("../../assets/images/hat_3.png"),
          animate: "animate11",
          delay: 0.3,
        },
        {
          name: "风电设备检修",
          icon: require("../../assets/images/hat_6.png"),
          animate: "animate15",
          delay: 0.1,
        },
        {
          name: "建筑工程监理",
          icon: require("../../assets/images/hat_4.png"),
          animate: "animate12",
          delay: 0.2,
        },
        {
          name: "通信设施建设",
          icon: require("../../assets/images/hat_5.png"),
          animate: "animate13",
          delay: 0.3,
        },
      ],
      animateData: {
        animate: false,
        animate1: false,
        animate2: false,
        animate3: false,
        animate4: false,
        animate5: false,
        animate6: false,
        animate7: false,
        animate8: false,
        animate9: false,
        animate10: false,
        animate11: false,
        animate12: false,
        animate13: false,
        animate14: false,
        animate14: false,
      },
    };
  },
  mounted() {
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {
    navHandle(id) {
      let target = document.getElementById(id);
      target.scrollIntoView();
    },
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? (this.animateData[i] = true)
          : (this.animateData[i] = false);
      }
    },
    scenesArrowHandle(val) {
      if (val == 1) {
        this.$refs.swiper.prev();
      } else {
        this.$refs.swiper.next();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  img {
    width: 100%;
    display: block;
  }
}

.scenes {
  margin-top: 38px;
  justify-content: start;
  flex-wrap: wrap;
  .scenes-item{
    width: 387px;
    margin-right: 9px;
    margin-bottom: 35px;
    &:nth-child(3n){
      margin-right: 0;
    }
  }
}
</style>