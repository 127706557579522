var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"survey",attrs:{"id":"survey"}},[_c('div',{ref:"animate",staticClass:"energy-title fadeOutUp",class:{ fadeInUp: _vm.animateData.animate }},[_c('img',{staticClass:"aiOPS_bjimg",attrs:{"src":require("../../assets/img/productSatellite_18.png"),"alt":""}})]),_c('div',{ref:"animate",staticClass:"survey-title fadeOutUp",class:{ fadeInUp: _vm.animateData.animate }},[_vm._v(" 产品简介 ")]),_c('p',{ref:"animate13",staticClass:"toptxt fadeOutUp",class:{ fadeInUp: _vm.animateData.animate13 }},[_vm._v("采用"),_c('span',[_vm._v("“获客+营销+交易+客服”")]),_vm._v("的客户全生命周期管理设计思路，以客户为中心分别打造连接客户、合作伙伴以及内部员工的“线索管理”、“客户管理”、“商机管理”、“产品管理”、“合同管理”、“营收管理”、“来电工作台”、“在线客服”等核心能力，以此为企业提供一站式全业务协作和运营的客户管理解决方案。 ")]),_c('div',{ref:"animate14",staticClass:"topbox fadeOutUp ",class:{ fadeInUp: _vm.animateData.animate14 , topbox2:_vm.istwo  }},[_vm._m(1)])]),_c('div',{staticClass:"features-wrap",attrs:{"id":"features-wrap"}},[_c('div',{ref:"animate1",staticClass:"energy-title fadeOutUp",class:{ fadeInUp: _vm.animateData.animate1 }},[_c('img',{staticClass:"aiOPS_bjimg",attrs:{"src":require("../../assets/img/productSatellite_20.png"),"alt":""}})]),_c('div',{ref:"animate1",staticClass:"features-title  fadeOutUp",class:{ fadeInUp: _vm.animateData.animate1 }},[_vm._v(" 核心能力 ")]),_c('div',{staticClass:"features-content"},_vm._l((_vm.features),function(item){return _c('div',{key:item.name,ref:item.animate,refInFor:true,staticClass:"features fadeOutUp",class:{ fadeInUp: _vm.animateData[item.animate] },style:({
          transitionDelay: item.delay + 's',
        })},[_c('div',{staticClass:"features-icon"},[_c('img',{attrs:{"src":item.icon,"alt":""}})]),_c('div',{staticClass:"features-name"},[_vm._v(_vm._s(item.name))]),_c('p',{staticClass:"features-txt"},[_vm._v(_vm._s(item.txt))])])}),0)]),_c('div',{staticClass:"scenes-wrap",attrs:{"id":"scenes-wrap"}},[_c('div',{ref:"animate12",staticClass:"energy-title fadeOutUp",class:{ fadeInUp: _vm.animateData.animate12 }},[_c('img',{staticClass:"aiOPS_bjimg",attrs:{"src":require("../../assets/img/productSatellite_19.png"),"alt":""}})]),_c('div',{ref:"animate12",staticClass:"scenes-title survey-title fadeOutUp",class:{ fadeInUp: _vm.animateData.animate12 }},[_vm._v(" 产品特点 ")]),_c('div',{staticClass:"scenes"},_vm._l((_vm.scenes2),function(item){return _c('div',{key:item.name,ref:item.animate,refInFor:true,staticClass:"cp-item fadeOutUp",class:{ fadeInUp: _vm.animateData[item.animate] },style:({
          backgroundImage: 'url(' + item.bg + ')',
          transitionDelay: item.delay + 's',
        })},[_c('div',{ref:item.animate,refInFor:true,staticClass:"fadeOutUp",class:{ fadeInUp: _vm.animateData[item.animate] },style:({
          transitionDelay: (item.delay*1+0.3) + 's',
        })},[_c('div',{staticClass:"cpimg"},[_c('img',{attrs:{"src":item.icon,"alt":""}})]),_c('div',{staticClass:"cph3"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"cptxt"},[_vm._v(_vm._s(item.title))])])])}),0)]),_c('div',{staticClass:"scenes-wrap",attrs:{"id":"scenes-wrap"}},[_c('div',{ref:"animate2",staticClass:"energy-title fadeOutUp",class:{ fadeInUp: _vm.animateData.animate2 }},[_c('img',{staticClass:"aiOPS_bjimg",attrs:{"src":require("../../assets/img/productSatellite_21.png"),"alt":""}})]),_c('div',{ref:"animate2",staticClass:"scenes-title survey-title fadeOutUp",class:{ fadeInUp: _vm.animateData.animate2 }},[_vm._v(" 应用场景 ")]),_c('div',{staticClass:"scenes  l-wrap",staticStyle:{"margin-bottom":"80px"}},_vm._l((_vm.scenes),function(item){return _c('div',{key:item.name,ref:item.animate,refInFor:true,staticClass:"scenes-item fadeOutUp",class:{ fadeInUp: _vm.animateData[item.animate] },style:({
          transitionDelay: item.delay + 's',
        })},[_c('div',{staticStyle:{"height":"250px"}},[_c('img',{attrs:{"src":item.icon,"alt":""}})]),_c('span',{staticClass:"yytxt"},[_vm._v(_vm._s(item.name))])])}),0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner"},[_c('div',{staticClass:"h3"},[_c('img',{attrs:{"src":require("../../assets/img/productRobot_18.png")}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"topleft"},[_c('img',{staticClass:"topleftbg ",attrs:{"src":require("../../assets/img/productHSE_2.png"),"alt":""}}),_c('img',{staticClass:"topleftimg1 tran",attrs:{"src":require("../../assets/img/productHSE_3.png"),"alt":""}}),_c('img',{staticClass:"topleftimg2 tran",attrs:{"src":require("../../assets/img/productHSE_4.png"),"alt":""}}),_c('img',{staticClass:"topleftimg3 tran",attrs:{"src":require("../../assets/img/productHSE_5.png"),"alt":""}}),_c('img',{staticClass:"topleftimg4 tran",attrs:{"src":require("../../assets/img/productHSE_21.png"),"alt":""}})])}]

export { render, staticRenderFns }