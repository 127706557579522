<template>
  <div>
    <div class="banner">
      <!-- <h3>CRM客户关系管理系统</h3>
      <p>Transformer intelligent testing product based on micro robot</p> -->
        <div  class='h3'>
          <img src='../../assets/img/pr_2.png'>
        </div>
    </div>
    <!-- <div class="product-nav-wrap">
      <div class="product-nav">
        <a
          v-for="item in navList"
          :key="item.name"
          @click="navHandle(item.url)"
          >{{ item.name }}</a
        >
      </div>
    </div> -->
    <div class="survey" id="survey">
       <div
        class="energy-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        <img class='aiOPS_bjimg' src="../../assets/img/productSatellite_18.png" alt="" />
      </div>
      <div
        class="survey-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        产品简介
      </div>
      <div class='topbox  l-between'
         ref="animate14"

      >
            <div class='topleft fadeOutUp' :class="{ fadeInUp: animateData.animate14  }">
                <img
                  class="fadeOutUp"
                  :class="{ fadeInUp: animateData.animate14   }"
                  v-for="(item,index) in topleftimgList"
                  :Key="index"
                  :style="item.style"
                  :src="item.img"
                  alt=""
              />
                <!-- <img class='topleftimg2 tran' src="../../assets/img/productRobot_4.png" alt="" />
                <img class='topleftimg3 tran' src="../../assets/img/productRobot_5.png" alt="" /> -->
            </div>
            <p class='toptxt ' ><span></span>采用B/S架构，集项目管理、需求管理、缺陷管理、脚本设计、用例管理、自动化任务管理、报表管理、系统管理等功能于一体，覆盖“API自动化测试”、“UI自动化测试”、“APP自动化测试”、“性能效率测试”、“安全渗透测试”、“业务可用巡测”等测试类型，完整覆盖自动化测试项目的全过程，可快速协助用户建立自动化测试管理体系建立，提高测试效率与质量。</p>
      </div>
    </div>
    <div class="features-wrap" id="features-wrap">
       <div
        class="energy-title fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
      >
        <img class='aiOPS_bjimg' src="../../assets/img/productSatellite_20.png" alt="" />
      </div>
      <div
        class="features-title  fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
      >
        核心能力
      </div>
      <div class="features-content" style="flex-wrap: wrap;">
        <div
          class="features fadeOutUp"
          v-for="item in features"
          :key="item.name"
          :style="{
            transitionDelay: item.delay + 's',
          }"
          :class="{ fadeInUp: animateData[item.animate] }"
          :ref="item.animate"
        >
          <div class="features-icon">
            <img :src="item.icon" alt="" />
          </div>
          <div class="features-name">{{ item.name }}</div>
          <p class='features-txt'>{{item.txt}}</p>
        </div>
      </div>
    </div>
     <div class="scenes-wrap" id="scenes-wrap">
        <div
        class="energy-title fadeOutUp"
        ref="animate12"
        :class="{ fadeInUp: animateData.animate12 }"
      >
        <img class='aiOPS_bjimg' src="../../assets/img/productSatellite_19.png" alt="" />
      </div>
      <div
        class="scenes-title survey-title fadeOutUp"
        ref="animate12"
        :class="{ fadeInUp: animateData.animate12 }"
      >
        产品特点
      </div>

      <div class="scenes">
        <div
          class="cp-item fadeOutUp"
          v-for="item in scenes2"
          :key="item.name"
          :style="{
            backgroundImage: 'url(' + item.bg + ')',
            transitionDelay: item.delay + 's',
          }"
          :class="{ fadeInUp: animateData[item.animate] }"
          :ref="item.animate"
        >
         <div
          class='fadeOutUp'
         :class="{ fadeInUp: animateData[item.animate] }"
         :ref="item.animate"
          :style="{
            transitionDelay: (item.delay*1+0.3) + 's',
          }">
             <div class='cpimg' :style="`top:${item.top}px;`">
              <img :src="item.icon" alt="" />
            </div>
            <div class='cph3'>{{ item.name }}</div>
            <div class='cptxt'>{{ item.title }}</div>
        </div>
        </div>
      </div>
    </div>

    <div class="scenes-wrap" id="scenes-wrap">
        <div
        class="energy-title fadeOutUp"
        ref="animate2"
        :class="{ fadeInUp: animateData.animate2 }"
      >
        <img class='aiOPS_bjimg' src="../../assets/img/productSatellite_21.png" alt="" />
      </div>
      <div
        class="scenes-title survey-title fadeOutUp"
        ref="animate2"
        :class="{ fadeInUp: animateData.animate2 }"
      >
        产品优势
      </div>

       <div class="scenes" style='margin-bottom:80px'>
        <div
          class="scenes-item fadeOutUp bomlist"
          v-for="item in scenes"
          :key="item.name"
          :style="{
            transitionDelay: item.delay + 's',
          }"
          :class="{ fadeInUp: animateData[item.animate] }"
          :ref="item.animate"
        >
          <div style="height: 90px;text-align:center">
            <img  :src="item.icon" alt="" style='margin: 0 auto;' />
          </div>
          <div class='yytitle'>{{ item.name }}</div>
          <div class='yytext'>{{ item.txt }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isElementNotInViewport } from "../../utils/index.js";
export default {
  data() {
    return {
      istwo:true,
      navList: [
        {
          name: "产品概况",
          url: "survey",
        },
        {
          name: "产品特点",
          url: "features-wrap",
        },
        {
          name: "应用场景",
          url: "scenes-wrap",
        },
      ],
      topleftimgList:[
        {img:require('../../assets/img/pr_3.png'),style:'top:-35px;left:50%;transform: translate(-50%,0)'},
        {img:require('../../assets/img/pr_4.png'),style:'top:55px;right:-21px'},
        {img:require('../../assets/img/pr_5.png'),style:'top:226px;right:-21px'},
        {img:require('../../assets/img/pr_6.png'),style:'bottom:-35px;left:50%;transform: translate(-50%,0)'},
        {img:require('../../assets/img/pr_7.png'),style:'top:226px;left:-21px'},
        {img:require('../../assets/img/pr_8.png'),style:'top:55px;left:-21px'},
      ],
      product: [
        {
          name: "3D虚拟模型",
          icon: require("../../assets/images/product-icon_6.png"),
          animate: "animate3",
          delay: 0.1,
        },
        {
          name: "物联网感知",
          icon: require("../../assets/images/product-icon_7.png"),
          animate: "animate4",
          delay: 0.2,
        },
        {
          name: "三维可视化智能管控平台",
          icon: require("../../assets/images/product-icon_8.png"),
          animate: "animate5",
          delay: 0.3,
        },
      ],
      features: [
       {
          name: "接口自动化测试能力",
          icon: require("../../assets/img/productRobot_21.png"),
          bg: require("../../assets/images/features-bg_9.png"),
          animate: "animate8",
          txt:"针对各类接口，通过编写自动化测试脚本，实现接口功能的自动化测试",
          delay: 0.3,
        },
        {
          name: "界面自动化测试能力",
          icon: require("../../assets/img/productRobot_22.png"),
          bg: require("../../assets/images/features-bg_8.png"),
          animate: "animate7",
          txt:"针对B/S架构系统，通过录制界面组件+测试脚本，实现界面自动化测试",
          delay: 0.2,
        },
         {
          name: "移动端自动化测试能力",
          icon: require("../../assets/img/productRobot_23.png"),
          bg: require("../../assets/images/features-bg_7.png"),
          animate: "animate6",
          txt:"针对APP、H5类型软件，通过录制组件+测试脚本，实现移动端自动化测试",
          delay: 0.1,
        },
        {
          name: "性能效率测试能力",
          icon: require("../../assets/img/productRobot_24.png"),
          bg: require("../../assets/images/features-bg_7.png"),
          animate: "animate102",
          txt:"针对接口、界面等，通过压力、负载等指标配置，实现系统性能效率测试",
          delay: 0.1,
        },
        {
          name: "安全渗透测试能力",
          icon: require("../../assets/img/productRobot_25.png"),
          bg: require("../../assets/images/features-bg_7.png"),
          animate: "animate101",
          txt:"针对系统、代码等，通过报文渗透攻击、扫描代码漏洞，实现系统安全测试",
          delay: 0.2,
        },
        {
          name: "业务可用巡测能力",
          icon: require("../../assets/img/productRobot_26.png"),
          bg: require("../../assets/images/features-bg_7.png"),
          animate: "animate100",
          txt:"针对系统，通过检测系统功能、环境相关健康指标，实现系统业务可用性测试",
          delay: 0.3,
        },

      ],
      scenes: [
        {
          name: "更全面",
          txt:"可实现接口、界面、移动端、性能、安全的全面测试",
          icon: require("../../assets/img/robot_1.png"),
          animate: "animate9",
          delay: 0.1,
        },
        {
          name: "更高效",
          txt:"可自动生成高覆盖的测试用例，大幅提升工作效率",
          icon: require("../../assets/img/robot_4.png"),
          animate: "animate10",
          delay: 0.2,
        },
        {
          name: "更方便",
          txt:"可实现脚本与数据分离，便于维护和共享",
          icon: require("../../assets/img/robot_3.png"),
          animate: "animate11",
          delay: 0.3,
        },
        {
          name: "更灵活",
          txt:"可与其他系统集成，支持二次开发和私有化部署",
          icon: require("../../assets/img/robot_5.png"),
          animate: "animate24",
          delay: 0.4,
        },
      ],
       scenes2: [

        {
          name: "一站式测试平台",
          title:"以ATF为核心，集成多种自动化测试工具，全面满足研发、运维的测试需求",
          bg: require("../../assets/img/productRobot_16.png"),
          icon: require("../../assets/img/productRobot_12.png"),
          animate: "animate21",
          top:-100,
          delay: 0.1,
        },
        {
          name: "生态化体系支撑",
          title:"以DevOps理念为支撑，通过管理统一、能力共享，实现提质、降本和增效",
          bg: require("../../assets/img/productRobot_15.png"),
          icon: require("../../assets/img/productRobot_10.png"),
          animate: "animate22",
          top:-117,
          delay: 0.2,
        },
        {
          name: "全闭环测试管理",
          title:"贯穿需求、用例、缺陷管理全过程，实现需求与用例、用例与缺陷双向追踪",
          bg: require("../../assets/img/productRobot_14.png"),
          icon: require("../../assets/img/productRobot_11.png"),
          animate: "animate23",
          top:-100,
          delay: 0.3,
        },
        {
          name: "工具化应用能力",
          title:"构建多种类工具集，全方位满足客户在环境、测试、管理、运维的核心诉求",
          bg: require("../../assets/img/productRobot_20.png"),
          icon: require("../../assets/img/productRobot_19.png"),
          animate: "animate23",
          top:-100,
          delay: 0.4,
        },
      ],

      animateData: {
        animate: false,
        animate1: false,
        animate2: false,
        animate3: false,
        animate4: false,
        animate5: false,
        animate6: false,
        animate7: false,
        animate8: false,
        animate9: false,
        animate10: false,
        animate11: false,
        animate12: false,
        animate100: false,
        animate101: false,
        animate102: false,

      },
    };
  },
  mounted() {
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {
    navHandle(id) {
      let target = document.getElementById(id);
      target.scrollIntoView();
    },
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? (this.animateData[i] = true)
          : (this.animateData[i] = false);

          if(i=='animate14' && !isElementNotInViewport(this.$refs[i])){
              setTimeout(()=>{
                this.istwo = false

              },700)
          }
          if(i=='animate14' && isElementNotInViewport(this.$refs[i])){
                this.istwo = true
          }
      }
    },
    scenesArrowHandle(val) {
      if (val == 1) {
        this.$refs.swiper.prev();
      } else {
        this.$refs.swiper.next();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
    width:100%;
    height:433px;
    background: url(../../assets/img/pr_1.png) no-repeat center center;
    background-size:1920px 433px;
    .h3{
        position: relative;
        top:130px;
        width:1180px;
        margin: 0px auto;
    }
    h3{
        width: 578px;
        height: 45px;
        font-size: 34px;
        color: #FFFFFF;
        line-height: 45px;
        width:1180px;
        font-weight: 400;
        margin: 0px auto;
        position: relative;
        top:160px;
    }
    p{
        height: 19px;
        font-size: 14px;
        color: rgba(255, 255, 255, .49);
        line-height: 19px;
        width:1180px;
        margin: 0px auto;
        position: relative;
        top:182px;

    }
}

.scenes {
  margin-top: 38px;

  .scenes-item {
    width: 1181px;
    height:184px;
    background:url(../../assets/img/productRobot_17.png) no-repeat;
    background-size:100% 100%;
    &:hover{
      transition: all 0.1s linear;
      transition-delay: 0s !important;
    }
    h3 {
        height: 26px;
        font-size: 26px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 26px;
        text-align: center;
        padding-top:84px;
    }


  }
}
.energy-title{
  text-align: center;
}
.survey-title{
  height: 37px;
  font-size: 28px;
  color: #414B59;
  line-height: 37px;
  position: relative;
  top:-23px;
}
.features-title{
  font-size: 28px;
  color: #FFFFFF;
  line-height: 37px;
  position: relative;
  top:-23px;
}
.features-wrap{
  height: 813px;
  background: #3E76E0;

}
.features{
  width:284px !important;
  padding:0;
  margin-right: 109px;
  margin-top: 25px;
  &:nth-of-type(3n){
    margin-right: 0;
  }
  .features-icon{

  }
  .features-name{
    margin-top: 16px !important;
    height: 48px;
    color: #FFFFFF;
  }
  .features-txt{
    height: 90px;
    font-size: 14px;
    color: rgba(255, 255, 255, .66);
    line-height: 30px;
  }
}
.scenes .bomlist{
  width: 280px;
  height: 297px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px 9px rgba(132,181,255,0.08);
  border-radius: 4px;
  padding-top:40px;
  img{
    width:78px;
    margin: 0 auto;
  }
  .yytitle{
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #414B59;
    line-height: 26px;
    margin-top:17px;
  }
  .yytext{
    text-align: center;
    width: 230px;
    height: 60px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #999999;
    line-height: 24px;
    margin:0 auto;
    margin-top:11px;

  }
}
.yytxt{
  font-size: 18px !important;
  color: #909399 !important;
}
.toptxt{
    width: 576px;
    height: 128px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #909399;
    line-height: 32px;
    margin-top:102px;
    span{
        color:rgba(65, 75, 89, 1);
        font-size:20px;
    }
}
.cp-item{
  width:289px;
  height:378px;
  text-align: center;
  padding-top:150px;
  background:#396BE3;
  position: relative;
  display: block;
  .cpimg{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .cph3{
    height: 30px;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 24px;
    margin:24px 0 7px 0;

  }
  .cptxt{
      width: 214px;
      height: 48px;
      font-size: 14px;
      color: rgba(255, 255, 255, .9);
      line-height: 24px;
      margin: 0 auto;
  }
}
.cp-item:hover{
    background:#396BE3;
    background-image:none !important;
  }
// 产品简介图
.topbox{
    width:1092px;
    margin:0 auto;
    margin-top:70px;
    margin-bottom:50px;
    .topleft{
        width: 340px;
        height: 340px;
        background: url(../../assets/img/pr_9.png) no-repeat;
        background-size: 100%;
        position: relative;
        img{
            position: absolute;
            transition-delay: 0.2s;
        }
        .topleftimg1{
            top:280px;
            right:-13px;
        }
        .topleftimg2{
            top:280px;
            left:-13px;
        }
        .topleftimg3{
            top:-57px;
            left:154px;
        }
    }
}
// .topbox2{
//     .topleft{
//         .topleftimg1{
//             top:210px;
//             right:57px;
//             opacity: 0;
//         }
//         .topleftimg2{
//             top:210px;
//             left:57px;
//             opacity: 0;
//         }
//         .topleftimg3{
//             top:43px;
//             left:154px;
//             opacity: 0;
//         }
//     }
// }
.tran{
    transition: all 1s ease-in;
    -webkit-transition:all 1s ease-in;
  }
  .scenes .scenes-item img:hover {
    transform: scale(1) !important;
  }
  .scenes .scenes-item:hover {
    transform: scale(1.1) !important;
  }
</style>
