var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"survey",attrs:{"id":"survey"}},[_c('div',{ref:"animate",staticClass:"energy-title fadeOutUp",class:{ fadeInUp: _vm.animateData.animate }},[_c('img',{staticClass:"aiOPS_bjimg",attrs:{"src":require("../../assets/img/productSatellite_18.png"),"alt":""}})]),_c('div',{ref:"animate",staticClass:"survey-title fadeOutUp",class:{ fadeInUp: _vm.animateData.animate }},[_vm._v(" 产品简介 ")]),_c('div',{ref:"animate14",staticClass:"topbox  l-between"},[_c('div',{staticClass:"topleft fadeOutUp",class:{ fadeInUp: _vm.animateData.animate14  }},_vm._l((_vm.topleftimgList),function(item,index){return _c('img',{staticClass:"fadeOutUp",class:{ fadeInUp: _vm.animateData.animate14   },style:(item.style),attrs:{"Key":index,"src":item.img,"alt":""}})}),0),_vm._m(1)])]),_c('div',{staticClass:"features-wrap",attrs:{"id":"features-wrap"}},[_c('div',{ref:"animate1",staticClass:"energy-title fadeOutUp",class:{ fadeInUp: _vm.animateData.animate1 }},[_c('img',{staticClass:"aiOPS_bjimg",attrs:{"src":require("../../assets/img/productSatellite_20.png"),"alt":""}})]),_c('div',{ref:"animate1",staticClass:"features-title  fadeOutUp",class:{ fadeInUp: _vm.animateData.animate1 }},[_vm._v(" 核心能力 ")]),_c('div',{staticClass:"features-content",staticStyle:{"flex-wrap":"wrap"}},_vm._l((_vm.features),function(item){return _c('div',{key:item.name,ref:item.animate,refInFor:true,staticClass:"features fadeOutUp",class:{ fadeInUp: _vm.animateData[item.animate] },style:({
          transitionDelay: item.delay + 's',
        })},[_c('div',{staticClass:"features-icon"},[_c('img',{attrs:{"src":item.icon,"alt":""}})]),_c('div',{staticClass:"features-name"},[_vm._v(_vm._s(item.name))]),_c('p',{staticClass:"features-txt"},[_vm._v(_vm._s(item.txt))])])}),0)]),_c('div',{staticClass:"scenes-wrap",attrs:{"id":"scenes-wrap"}},[_c('div',{ref:"animate12",staticClass:"energy-title fadeOutUp",class:{ fadeInUp: _vm.animateData.animate12 }},[_c('img',{staticClass:"aiOPS_bjimg",attrs:{"src":require("../../assets/img/productSatellite_19.png"),"alt":""}})]),_c('div',{ref:"animate12",staticClass:"scenes-title survey-title fadeOutUp",class:{ fadeInUp: _vm.animateData.animate12 }},[_vm._v(" 产品特点 ")]),_c('div',{staticClass:"scenes"},_vm._l((_vm.scenes2),function(item){return _c('div',{key:item.name,ref:item.animate,refInFor:true,staticClass:"cp-item fadeOutUp",class:{ fadeInUp: _vm.animateData[item.animate] },style:({
          backgroundImage: 'url(' + item.bg + ')',
          transitionDelay: item.delay + 's',
        })},[_c('div',{ref:item.animate,refInFor:true,staticClass:"fadeOutUp",class:{ fadeInUp: _vm.animateData[item.animate] },style:({
          transitionDelay: (item.delay*1+0.3) + 's',
        })},[_c('div',{staticClass:"cpimg",style:(("top:" + (item.top) + "px;"))},[_c('img',{attrs:{"src":item.icon,"alt":""}})]),_c('div',{staticClass:"cph3"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"cptxt"},[_vm._v(_vm._s(item.title))])])])}),0)]),_c('div',{staticClass:"scenes-wrap",attrs:{"id":"scenes-wrap"}},[_c('div',{ref:"animate2",staticClass:"energy-title fadeOutUp",class:{ fadeInUp: _vm.animateData.animate2 }},[_c('img',{staticClass:"aiOPS_bjimg",attrs:{"src":require("../../assets/img/productSatellite_21.png"),"alt":""}})]),_c('div',{ref:"animate2",staticClass:"scenes-title survey-title fadeOutUp",class:{ fadeInUp: _vm.animateData.animate2 }},[_vm._v(" 产品优势 ")]),_c('div',{staticClass:"scenes",staticStyle:{"margin-bottom":"80px"}},_vm._l((_vm.scenes),function(item){return _c('div',{key:item.name,ref:item.animate,refInFor:true,staticClass:"scenes-item fadeOutUp bomlist",class:{ fadeInUp: _vm.animateData[item.animate] },style:({
          transitionDelay: item.delay + 's',
        })},[_c('div',{staticStyle:{"height":"90px","text-align":"center"}},[_c('img',{staticStyle:{"margin":"0 auto"},attrs:{"src":item.icon,"alt":""}})]),_c('div',{staticClass:"yytitle"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"yytext"},[_vm._v(_vm._s(item.txt))])])}),0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner"},[_c('div',{staticClass:"h3"},[_c('img',{attrs:{"src":require("../../assets/img/pr_2.png")}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"toptxt "},[_c('span'),_vm._v("采用B/S架构，集项目管理、需求管理、缺陷管理、脚本设计、用例管理、自动化任务管理、报表管理、系统管理等功能于一体，覆盖“API自动化测试”、“UI自动化测试”、“APP自动化测试”、“性能效率测试”、“安全渗透测试”、“业务可用巡测”等测试类型，完整覆盖自动化测试项目的全过程，可快速协助用户建立自动化测试管理体系建立，提高测试效率与质量。")])}]

export { render, staticRenderFns }