<template>
    <div>
      <div class="banner">
        <!-- <h3>CRM客户关系管理系统</h3>
        <p>Transformer intelligent testing product based on micro robot</p> -->
          <div  class='h3'>
            <img src='../../assets/img/2024/d1.png'>
          </div>
      </div>
      <!-- <div class="product-nav-wrap">
        <div class="product-nav">
          <a
            v-for="item in navList"
            :key="item.name"
            @click="navHandle(item.url)"
            >{{ item.name }}</a
          >
        </div>
      </div> -->
      <div class="survey" id="survey" ref="animate14" style="overflow: hidden;">
         <div
          class="energy-title fadeOutUp"
          ref="animate"
          :class="{ fadeInUp: animateData.animate }"
        >
          <img class='aiOPS_bjimg' src="../../assets/img/productSatellite_18.png" alt="" />
        </div>
        <div
          class="survey-title fadeOutUp"
          ref="animate"
          :class="{ fadeInUp: animateData.animate }"
        >
          产品简介
        </div>
      
              
              <p class='toptxt fadeOutUp'
        ref="animate13"
        :class="{ fadeInUp: animateData.animate13 }"
              >数据资产确权登记平台，围绕数据要素并以区块链、数据安全和人工智能等技术为支撑，面向企业、数据服务商、平台运营方、监管机构、评估机构等用户群体，构建支持<span>标识解析、确权登记、质量评价、价值评估、数据交易、资产入表</span>的数据资产生态服务体系。</p>
              <div class='topleft fadeOutUp'   :class="{ fadeInUp: animateData.animate14  }">
                  <img
                    class="fadeOutUp"
                    :class="{ fadeInUp: animateData.animate14   }"
                    v-for="(item,index) in topleftimgList"
                    :Key="index"
                    :style="item.style"
                    :src="item.img"
                    alt=""
                    
                />
                  <!-- <img class='topleftimg2 tran' src="../../assets/img/productRobot_4.png" alt="" />
                  <img class='topleftimg3 tran' src="../../assets/img/productRobot_5.png" alt="" /> -->
              </div>
      </div>
      <div class="features-wrap" id="features-wrap">
         <div
          class="energy-title fadeOutUp"
          ref="animate1"
          :class="{ fadeInUp: animateData.animate1 }"
        >
          <img class='aiOPS_bjimg' src="../../assets/img/productSatellite_20.png" alt="" />
        </div>
        <div
          class="features-title  fadeOutUp"
          ref="animate1"
          :class="{ fadeInUp: animateData.animate1 }"
        >
          核心能力
        </div>
        <div class="features-content" style="flex-wrap: wrap;">
          <div
            class="features fadeOutUp"
            v-for="item in features"
            :key="item.name"
            :style="{
              transitionDelay: item.delay + 's',
            }"
            :class="{ fadeInUp: animateData[item.animate] }"
            :ref="item.animate"
          >
            <div class="features-icon">
              <img :src="item.icon" alt="" />
            </div>
            <div class="features-name">{{ item.name }}</div>
            <p class='features-txt'>{{item.txt}}</p>
          </div>
        </div>
      </div>
       <div class="scenes-wrap" id="scenes-wrap">
          <div
          class="energy-title fadeOutUp"
          ref="animate12"
          :class="{ fadeInUp: animateData.animate12 }"
        >
          <img class='aiOPS_bjimg' src="../../assets/img/productSatellite_19.png" alt="" />
        </div>
        <div
          class="scenes-title survey-title fadeOutUp"
          ref="animate12"
          :class="{ fadeInUp: animateData.animate12 }"
        >
          产品特点
        </div>
  
        <div class="scenes">
          <div
            class="cp-item fadeOutUp"
            v-for="item in scenes2"
            :key="item.name"
            :style="{
              backgroundImage: 'url(' + item.bg + ')',
              transitionDelay: item.delay + 's',
            }"
            :class="{ fadeInUp: animateData[item.animate] }"
            :ref="item.animate"
          >
           <div
            class='fadeOutUp'
           :class="{ fadeInUp: animateData[item.animate] }"
           :ref="item.animate"
            :style="{
              transitionDelay: (item.delay*1+0.3) + 's',
            }">
               <div class='cpimg' :style="`top:${item.top}px;`">
                <img :src="item.icon" alt="" />
              </div>
              <div class='cph3'>{{ item.name }}</div>
              <div class='cptxt'>{{ item.title }}</div>
          </div>
          </div>
        </div>
      </div>
  
      <div class="scenes-wrap" id="scenes-wrap">
          <div
          class="energy-title fadeOutUp"
          ref="animate2"
          :class="{ fadeInUp: animateData.animate2 }"
        >
          <img class='aiOPS_bjimg' src="../../assets/img/productSatellite_21.png" alt="" />
        </div>
        <div
          class="scenes-title survey-title fadeOutUp"
          ref="animate2"
          :class="{ fadeInUp: animateData.animate2 }"
        >
          产品优势
        </div>
  
         <div class="scenes" style='margin-bottom:80px'>
          <div
            class="scenes-item fadeOutUp bomlist"
            v-for="(item,index) in scenes"
            :key="item.name"
            :style="{
              transitionDelay: item.delay + 's',
            }"
            :class="{ fadeInUp: animateData[item.animate] }"
            :ref="item.animate"
          >
            <div style="height: 90px;text-align:center">
              <img  :src="item.icon" alt="" style='margin: 0 auto;' />
            </div>
            <div class='yytitle'>{{ item.name }}</div>
            <div class='yytext'>{{ item.txt }}</div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { isElementNotInViewport } from "../../utils/index.js";
  export default {
    data() {
      return {
        istwo:true,
        navList: [
          {
            name: "产品概况",
            url: "survey",
          },
          {
            name: "产品特点",
            url: "features-wrap",
          },
          {
            name: "应用场景",
            url: "scenes-wrap",
          },
        ],
        topleftimgList:[
          {img:require('../../assets/img/2024/d4.png'),style:'top:238px;left:43px; transitionDelay:1s'},
          {img:require('../../assets/img/2024/d5.png'),style:'top:99px;left:127px;transitionDelay:.7s'},
          {img:require('../../assets/img/2024/d6.png'),style:'top:29px;left:346px;transitionDelay:.4s'},
          {img:require('../../assets/img/2024/d7.png'),style:'top:29px;right:365px; transitionDelay:.4s'},
          {img:require('../../assets/img/2024/d8.png'),style:'top:99px;right:133px; transitionDelay:.7s'},
          {img:require('../../assets/img/2024/d9.png'),style:'top:238px;right:43px; transitionDelay:1s'},
        ],
        product: [
          {
            name: "3D虚拟模型",
            icon: require("../../assets/images/product-icon_6.png"),
            animate: "animate3",
            delay: 0.1,
          },
          {
            name: "物联网感知",
            icon: require("../../assets/images/product-icon_7.png"),
            animate: "animate4",
            delay: 0.2,
          },
          {
            name: "三维可视化智能管控平台",
            icon: require("../../assets/images/product-icon_8.png"),
            animate: "animate5",
            delay: 0.3,
          },
        ],
        features: [
         {
            name: "数据登记能力",
            icon: require("../../assets/img/2024/d10.png"),
            bg: require("../../assets/images/features-bg_9.png"),
            animate: "animate8",
            txt:"采用端、云协同的方式，实现数据资产上报、登记、审核、发证的全流程管理",
            delay: 0.4,
          },
          {
            name: "数据评估能力",
            icon: require("../../assets/img/2024/d11.png"),
            bg: require("../../assets/images/features-bg_8.png"),
            animate: "animate7",
            txt:"通过企业侧、资产侧及数据侧多维度的质量评价，实现数据资产的全面评估",
            delay: 0.3,
          },
           {
            name: "数据服务能力",
            icon: require("../../assets/img/2024/d12.png"),
            bg: require("../../assets/images/features-bg_7.png"),
            animate: "animate6",
            txt:"通过数据服务商的能力引入，实现企业数据资产价值释放和收益转化效率提升",
            delay: 0.2,
          },
          {
            name: "数据交易能力",
            icon: require("../../assets/img/2024/d13.png"),
            bg: require("../../assets/images/features-bg_7.png"),
            animate: "animate102",
            txt:"采用“可用不可见、可控可计量”的应用模式，实现数据的安全共享和可信交易",
            delay: 0.1,
          },
         
  
        ],
        scenes: [
          {
            name: "应用等级高",
            txt:"国家级、城市级纵横联动，更具规模化、体系化",
            icon: require("../../assets/img/2024/d22.png"),
            animate: "animate9",
            delay: 0.1,
          },
          {
            name: "运营门槛低",
            txt:"企业、服务商生态共建，降低数据资产运营门槛",
            icon: require("../../assets/img/2024/d23.png"),
            animate: "animate10",
            delay: 0.2,
          },
          {
            name: "确权范围广",
            txt:"确权交易覆盖数据及衍生产品，更利于价值释放",
            icon: require("../../assets/img/2024/d24.png"),
            animate: "animate11",
            delay: 0.3,
          },
          
        ],
         scenes2: [
  
          {
            name: "一体化节点群",
            title:"1个国家节点+N个城市节点，全面满足统一管控、灵活自治的平台建设要求",
            bg: require("../../assets/img/2024/d14.png"),
            icon: require("../../assets/img/2024/d15.png"),
            animate: "animate21",
            top:-100,
            delay: 0.1,
          },
          {
            name: "生态化体系支撑",
            title:"支持标识解析、确权交易、质量评价、价值评估、资产入表的生态服务体系 ",
            bg: require("../../assets/img/2024/d16.png"),
            icon: require("../../assets/img/2024/d17.png"),
            animate: "animate22",
            top:-90,
            delay: 0.2,
          },
          {
            name: "数据服务商支持",
            title:"降低企业数据资产化运营门槛，大幅提升企业数据资产价值释放和收益转化效率",
            bg: require("../../assets/img/2024/d18.png"),
            icon: require("../../assets/img/2024/d19.png"),
            animate: "animate23",
            top:-95,
            delay: 0.3,
          },
          {
            name: "组件化基础服务",
            title:"支持区块链、大模型、隐私计算能力的灵活扩展，全方位满足客户的个性化需求",
            bg: require("../../assets/img/2024/d20.png"),
            icon: require("../../assets/img/2024/d21.png"),
            animate: "animate23",
            top:-97,
            delay: 0.4,
          },
        ],
  
        animateData: {
          animate: false,
          animate1: false,
          animate2: false,
          animate3: false,
          animate4: false,
          animate5: false,
          animate6: false,
          animate7: false,
          animate8: false,
          animate9: false,
          animate10: false,
          animate11: false,
          animate12: false,
          animate100: false,
          animate101: false,
          animate102: false,
  
        },
      };
    },
    mounted() {
      this.scrollToTop();
      // 监听滚动事件
      window.addEventListener("scroll", this.scrollToTop);
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.scrollToTop);
    },
    methods: {
      navHandle(id) {
        let target = document.getElementById(id);
        target.scrollIntoView();
      },
      // 滚动事件
      scrollToTop() {
        for (let i in this.$refs) {
          !isElementNotInViewport(this.$refs[i])
            ? (this.animateData[i] = true)
            : (this.animateData[i] = false);
  
            // if(i=='animate14' && !isElementNotInViewport(this.$refs[i])){
            //     setTimeout(()=>{
            //       this.istwo = false
  
            //     },700)
            // }
            // if(i=='animate14' && isElementNotInViewport(this.$refs[i])){
            //       this.istwo = true
            // }
        }
      },
      scenesArrowHandle(val) {
        if (val == 1) {
          this.$refs.swiper.prev();
        } else {
          this.$refs.swiper.next();
        }
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .banner {
      width:100%;
      height:433px;
      background: url(../../assets/img/2024/d2.png) no-repeat center center;
      background-size:1920px 433px;
      .h3{
          position: relative;
          top:130px;
          width:1180px;
          margin: 0px auto;
      }
      h3{
          width: 578px;
          height: 45px;
          font-size: 34px;
          color: #FFFFFF;
          line-height: 45px;
          width:1180px;
          font-weight: 400;
          margin: 0px auto;
          position: relative;
          top:160px;
      }
      p{
          height: 19px;
          font-size: 14px;
          color: rgba(255, 255, 255, .49);
          line-height: 19px;
          width:1180px;
          margin: 0px auto;
          position: relative;
          top:182px;
  
      }
  }
  
  .scenes {
    margin-top: 38px;
  
    .scenes-item {
      width: 1181px;
      height:184px;
      background:url(../../assets/img/productRobot_17.png) no-repeat;
      background-size:100% 100%;
      &:hover{
        transition: all 0.1s linear;
        transition-delay: 0s !important;
      }
      h3 {
          height: 26px;
          font-size: 26px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 26px;
          text-align: center;
          padding-top:84px;
      }
  
  
    }
  }
  .energy-title{
    text-align: center;
  }
  .survey-title{
    height: 37px;
    font-size: 28px;
    color: #414B59;
    line-height: 37px;
    position: relative;
    top:-23px;
  }
  .features-title{
    font-size: 28px;
    color: #FFFFFF;
    line-height: 37px;
    position: relative;
    top:-23px;
  }
  .features-wrap{
    height: 498px;
    background: #3E76E0;
  
  }
  .features{
    width:240px !important;
    padding:0;
    margin-top: 15px;
    &:nth-of-type(3n){
      margin-right: 0;
    }
    .features-icon{
  
    }
    .features-name{
      margin-top: 16px !important;
      height: 48px;
      color: #FFFFFF;
    }
    .features-txt{
      height: 90px;
      font-size: 14px;
      color: rgba(255, 255, 255, .66);
      line-height: 30px;
    }
  }
  .scenes .bomlist{
    width: 375px;
    height: 249px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 9px rgba(132,181,255,0.08);
    border-radius: 4px;
    padding-top:40px;
    img{
      width:78px;
      margin: 0 auto;
    }
    .yytitle{
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      color: #414B59;
      line-height: 26px;
      margin-top:17px;
    }
    .yytext{
      text-align: center;
      width: 320px;
      height: 60px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #999999;
      line-height: 24px;
      margin:0 auto;
      margin-top:11px;
  
    }
  }
  .yytxt{
    font-size: 18px !important;
    color: #909399 !important;
  }
  .toptxt{
    width: 1140px;
    height: 128px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #909399;
    line-height: 32px;
    margin:0 auto;
    margin-top:10px;
    text-align: center ;
      span{
          color:rgba(65, 75, 89, 1);
          font-size:20px;
      }
  }
  .cp-item{
    width:289px;
    height:378px;
    text-align: center;
    padding-top:150px;
    background:#396BE3;
    position: relative;
    display: block;
    .cpimg{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .cph3{
      height: 30px;
      font-size: 18px;
      color: #FFFFFF;
      line-height: 24px;
      margin:24px 0 7px 0;
  
    }
    .cptxt{
        width: 214px;
        height: 48px;
        font-size: 14px;
        color: rgba(255, 255, 255, .9);
        line-height: 24px;
        margin: 0 auto;
    }
  }
  .cp-item:hover{
      background:#396BE3;
      background-image:none !important;
    }
  // 产品简介图
 
      .topleft{
        width:1035px;
        height:515px;
        margin:0 auto;
          background: url(../../assets/img/2024/d3.png) no-repeat;
          background-size: 100%;
          position: relative;
          img{
              position: absolute;
              transition-delay: 0.2s;
          }
          .topleftimg1{
              top:280px;
              right:-13px;
          }
          .topleftimg2{
              top:280px;
              left:-13px;
          }
          .topleftimg3{
              top:-57px;
              left:154px;
          }
      }

  .tran{
      transition: all 1s ease-in;
      -webkit-transition:all 1s ease-in;
    }
    .scenes .scenes-item img:hover {
      transform: scale(1) !important;
    }
    .scenes .scenes-item:hover {
      transform: scale(1.1) !important;
    }
  </style>
  