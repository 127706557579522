<template>
    <div class='productWX '>
        <div class="box w1200">
            <div class="top">
               <img class="img1" src="../../assets/img/productWX_3.png">
               <div class="l-start">
                    <div class="top-h1">欢迎使用</div>
                    <div class="top-h2">商拓邦</div>
                    <div class="top-h3">小程序</div>
               </div>
               <div  class="top-p">拓人脉 · 抓商机 · 找融资</div>
               <div  class="top-txt">商拓邦是专为创业型企业家设计的轻熟圈信息服务类产品，通过该产品可以直观了解创业家的企业状况和产品方案，帮助创业家精准拓展人脉、智能捕捉商机、快速找到融资。</div>
               <div class="code">
                    <img  class="img10" src="../../assets/img/productWX_10.jpg">
                    <div class="codep">微信扫码打开小程序</div>
               </div>
               <img  class="img2" src="../../assets/img/productWX_4.png">
            </div>
            <div class="bom">
                <div class="bomh1">部分页面展示</div>
                <div class="bombox l-between">
                    <img  class="img3" src="../../assets/img/productWX_5.png">
                    <img  class="img3" src="../../assets/img/productWX_6.png">
                    <img  class="img3" src="../../assets/img/productWX_7.png">
                    <img  class="img3" src="../../assets/img/productWX_8.png">
                    <img  class="img3" src="../../assets/img/productWX_9.png">
                </div>
            </div>
        </div>



    </div>
           
    </template>
    
    <script>
    export default {
        props:['isshow','datas'],
        data () {
            return {
                datas:{}
            }
        },
        watch: {
         
        },
    
        mounted () {  
        
        },
        methods: {
        
        }
    }
    </script>
    
    <style lang="less" scoped>
        .w1200{
            width:1150px;
            margin:0 auto;
        }
        .productWX{
            background:url('../../assets/img/productWX_2.png') no-repeat center top;
            background-size:100% 950px;
            .top{
                height:950px;
                padding-top:80px;
                position: relative;
                .img1{
                    width:80px;
                    height:81px;
                    margin-bottom:36px;
                    
                }
                .img2{
                    width:815px;
                    height:910px; 
                    position: absolute;
                    right:0;
                    top:-80px;
                }
                .top-h1{
                    height: 48px;
                    font-size: 48px;
                    font-family: SourceHanSansCN-Light, SourceHanSansCN;
                    font-weight: 300;
                    color: #2D2F33;
                    line-height: 72px;
                }
                .top-h2{
                    height: 48px;
                    font-size: 48px;
                    font-family: SourceHanSansCN-Light, SourceHanSansCN;
                    font-weight: 900;
                    color: #2D2F33;
                    line-height: 72px;
                    margin-left:20px;
                }
                .top-h3{
                    width:64px;
                    height:30px;
                    line-height:30px;
                    text-align: center;
                    border-radius: 5px 5px 5px 0;
                    font-size: 16px;
                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                    font-weight: 400;
                    color: #FFFFFF;
                    background:url('../../assets/img/productWX_11.png') no-repeat center center;
                    background-size:100% 100%;
                    position: relative;
                    left:6px;
                    top:30px;
                }
                .top-p{
                    height: 28px;
                    font-size: 28px;
                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                    font-weight: 400;
                    color: #7B8188;
                    line-height: 42px;
                    margin-top:23px;
                }
                .top-txt{
                    width: 457px;
                    height: 96px;
                    font-size: 16px;
                    font-family: MicrosoftYaHei;
                    color: #7B8088;
                    line-height: 32px;
                    margin-top:58px;
                }
                .code{
                    width: 170px;
                    height: 198px;
                    background: #FFFFFF;
                    border-radius: 8px;
                    margin-top:87px;
                    text-align: center;
                    .img10{
                        width:148px;
                        height:147px;
                        margin-top:10px;
                    }
                    .codep{
                        height: 14px;
                        font-size: 14px;
                        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                        font-weight: 400;
                        color: #A1A1A2;
                        line-height: 21px;
                    }
                }
            }
            .bom{
                height: 685px;
                .bomh1{
                    font-size: 48px;
                    font-family: SourceHanSansCN-Light, SourceHanSansCN;
                    font-weight: 300;
                    color: #2D2F33;
                    line-height: 72px;
                    text-align: center;
                    margin-top:20px;
                }
                .bombox{
                    margin-top:64px;
                }
            }

        }
    </style>
    