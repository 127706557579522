<template>
    <div>
      <div class="banner">

          <div  class='h3'>
            <img src='../../assets/img/f2.png'>
          </div>
      </div>

      <div class="survey survey1" id="survey">
         <div
          class="energy-title fadeOutUp"
          ref="animate"
          :class="{ fadeInUp: animateData.animate }"
        >
          <img class='aiOPS_bjimg' src="../../assets/img/f3.png" alt="" />
        </div>
        <div
          class="survey-title fadeOutUp"
          ref="animate"
          :class="{ fadeInUp: animateData.animate }"
        >
          产品简介
        </div>
        <div class='topbox fadeOutUp'
           ref="animate"
          :class="{ fadeInUp: animateData.animate   }"
        >
              <p class='toptxt ' ><span></span>蜂筑平台聚焦工业互联网发展，通过整合标识解析系统、应用服务系统、运维监管系统、工业智脑和数据交易系统等专业系统，利用工业互联网标识解析体系助力企业数字化转型、智能化升级，实现企业高质量发展。利用工业数据赋能工业发展。</p>
        </div>
        <div class='topbox fadeOutUp topimgbox'
           ref="animate"
          :class="{ fadeInUp: animateData.animate   }"
        >
        <div class="im1 fadeOutUp l-around"
        :style="{
              transitionDelay: '0s',
            }"
            :class="{ fadeInUp: animateData.animate30 }"
            ref="animate30"
        >
            <img class='img1_1 imi tran' :class='{ opt:istree,xhdh:!istree}' src="../../assets/img/f58.png" alt="" />
            <img class='img1_2 imi tran' :class='{ opt:istree,xhdh:!istree}' src="../../assets/img/f59.png" alt="" />
            <img class='img1_3 imi tran' :class='{ opt:istree,xhdh:!istree}' src="../../assets/img/f60.png" alt="" />
            <img class='img1_4 imi tran' :class='{ opt:istree,xhdh:!istree}' src="../../assets/img/f61.png" alt="" />
          </div>
          <div class="im2 fadeOutUp"
            :style="{
              transitionDelay: '0.2s',
            }"
            :class="{ fadeInUp: animateData.animate31 }"
            ref="animate31"
            >
            <img class='img2_1 imi tran' :class='{ opt:istree,xhdh:!istree}' src="../../assets/img/f63.png" alt="" />
            <img class='img2_2 imi tran' :class='{ opt:istree,xhdh:!istree}' src="../../assets/img/f64.png" alt="" />
          </div>
          <div class="im3 fadeOutUp"
          :style="{
              transitionDelay: '0.4s',
            }"
            :class="{ fadeInUp: animateData.animate32 }"
            ref="animate32">
            <img class='img3_1' src="../../assets/img/f65.png" alt="" />
          </div>
          <div class="im4 fadeOutUp"
          :style="{
              transitionDelay: '0.6s',
            }"
            :class="{ fadeInUp: animateData.animate33 }"
            ref="animate33">
            <img class='img4_1 imgpro' src="../../assets/img/f66.png" alt="" />
          </div>
        </div>
      </div>

      <div class="survey nr hidden" id="dcmm">
        <div style="height:50px"></div>
         <div
          class="energy-title fadeOutUp"
          ref="animate1"
          :class="{ fadeInUp: animateData.animate1 }"
        >
          <img class='aiOPS_bjimg' src="../../assets/img/f32.png" alt="" />
        </div>
        <div
          class="survey-title fadeOutUp"
          ref="animate1"
          :class="{ fadeInUp: animateData.animate1 }"
        >
          平台构成
        </div>
        <div class='topbox fadeOutUp'
           ref="animate1"
          :class="{ fadeInUp: animateData.animate1 }"
        >
        <div class="ibox l-between">
            <div class="left">
                <img class='limg' :src="navData[idx].img" alt="" />
                <h3 class="h3">{{ navData[idx].title }}</h3>
                <p class="p">{{ navData[idx].txt }}</p>

                <h4 class="h4">核心能力<div class="xian"></div></h4>
                <div class="lbox">
                    <div class="l-start lboxrow" :class="{lboxrowborder:index2!=0}" v-for="(item,index2) in navData[idx].list">
                        <img class='listimg' :src="item.img" alt="" v-if="!navData[idx].type"/>
                        <div class="lrig" v-if="!navData[idx].type">
                            <h4 class="lh4">{{ item.title }}</h4>
                            <p class="lp">{{item.txt }}</p>
                        </div>
                        <div class="l-between" style="width:100%" v-if="navData[idx].type">
                            <div class="newrow l-start">
                                <img class='listimg' :src="item.img" alt=""/>
                                <div class="lrig">
                                    <h4 class="lh4">{{ item.title }}</h4>
                                    <p class="lp">{{item.txt }}</p>
                                </div>
                            </div>
                            <div  class="newrow l-start">
                                <img class='listimg' :src="item.img2" alt=""/>
                                <div class="lrig">
                                    <h4 class="lh4">{{ item.title2 }}</h4>
                                    <p class="lp">{{item.txt2 }}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="rig">
                <div class="l-start rig-row b" :class="{active:idx==index}" v-for="(itm,index) in navList" @click="navFn(itm,index)">
                    <img class='iimg' :src="itm.icon" alt="" />
                    <div class="txt">{{ itm.name }}</div>
                </div>
            </div>
        </div>

        </div>
      </div>



       <div class="features-wrap features-wrap3 " id="features-wrap ">
         <div
          class="energy-title fadeOutUp"
          ref="animate3"
          :class="{ fadeInUp: animateData.animate3 }"
        >
          <img class='aiOPS_bjimg' src="../../assets/img/f33.png" alt="" />
        </div>
        <div
          class="features-title  fadeOutUp"
          ref="animate3"
          :class="{ fadeInUp: animateData.animate3 }"
        >
          服务行业
        </div>
       <div class="features-box fadeOutUp"
       :class="{ fadeInUp: animateData.animate3 }">
        <div class="features-content">
          <div
            class="features fadeOutUp sizehove"
            v-for="item in features"
            :key="item.name"
            :style="{
              transitionDelay: item.delay + 's',
            }"
            :class="{ fadeInUp: animateData[item.animate] }"
            :ref="item.animate"
          >
            <div class="features-icon">
              <img :src="item.icon" alt="" />
            </div>
            <div class="features-name">{{ item.name }}</div>
          </div>

        </div>

       </div>
      </div>

      <div class="survey  dj hidden" id="leve">
         <div
          class="energy-title fadeOutUp"
          ref="animate2"
          :class="{ fadeInUp: animateData.animate2 }"
        >
          <img class='aiOPS_bjimg' src="../../assets/img/f34.png" alt="" />
        </div>
        <div
          class="survey-title fadeOutUp"
          ref="animate2"
          :class="{ fadeInUp: animateData.animate2 }"
        >
          解决方案
        </div>
        <div class='topbox fadeOutUp'
           ref="animate2"
          :class="{ fadeInUp: animateData.animate2 }"
        >
             <div class="jhead">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane :label="itm.name" :name="itm.name" v-for="itm in jList" :key="itm.name"></el-tab-pane>
                </el-tabs>
             </div>
             <div class="jcont">
                <div class="cont l-start">
                    <div class="left ">
                        <img class='jimg' :src="jData[activeIndex].img" alt="" />
                        <br/>
                        <h4 class="h4">{{ jData[activeIndex].title }}<div class="xian"></div></h4>
                        <p class="jp">{{ jData[activeIndex].txt }}</p>
                    </div>
                    <div class="rig">
                        <div class="l-start rbox" :class="{xian1:ihndex!=0}" v-for="(itm,ihndex) in jData[activeIndex].list">
                            <div class="dian"></div>
                            <div>
                                <h3 class="h3">{{ itm.title }}</h3>
                                <p class="rp">{{ itm.txt }}</p>
                            </div>

                        </div>
                    </div>
                </div>

             </div>

        </div>
      </div>

      <div class=" scenes-wrap scenes-wrap3  h852" id="scenes-wrap">
          <div
          class="energy-title fadeOutUp"
          ref="animate5"
          :class="{ fadeInUp: animateData.animate5 }"
        >
          <img class='aiOPS_bjimg' src="../../assets/img/f56.png" alt="" />
        </div>
        <div
          class="scenes-title survey-title fadeOutUp"
          ref="animate5"
          :class="{ fadeInUp: animateData.animate5 }"
        >
          业务分布地图
        </div>


         <div class="scenes2 " style='margin-bottom:80px'
         ref="animate5"
          >
          <div class='simg l-between'>
            <div class="disc fadeOutUp" :style="{  transitionDelay: '0.5s', }" :class="{ fadeInUp: animateData.animate5   }">
              蜂筑工业互联网平台的数据要素业务分布广泛，涉及不同行业，让企业数据在更大范围内发挥更大价值，助力中小企业发展，促进行业进步。目前服务的区域范围以北京为中心辐射至全国各地，涉及到
              <span class="fontB">北京、湖南、湖北、江苏、安徽、河南、广东、山东、山西、四川</span>
              等省份。
            </div>
            <img  class="fadeOutUp" :style="{  transitionDelay: '0.1s', }" src="../../assets/img/f47.png" alt="" :class="{ fadeInUp: animateData.animate5   }"/>
          </div>
        </div>

      </div>

      <div class="features-wrap features-wrap4 " id="features-wrap ">
        <div class="bg"></div>

         <div
          class="energy-title fadeOutUp"
          ref="animate4"
          :class="{ fadeInUp: animateData.animate4 }"
        >
          <img class='aiOPS_bjimg' src="../../assets/img/f48.png" alt="" />
        </div>
        <div
          class="features-title  fadeOutUp"
          ref="animate4"
          :class="{ fadeInUp: animateData.animate4 }"
        >
          应用场景
        </div>
       <div class="features-box fadeOutUp "
       :class="{ fadeInUp: animateData.animate4 }">
        <div class="features-content importre">
          <img class='imleft' src="../../assets/img/f53.png" alt="" />
          <img class='imrig' src="../../assets/img/f54.png" alt="" />
          <img class='imleftbtn b' src="../../assets/img/f74.png" alt="" v-if="imIndex>0"  @click="imBtn('left')"/>
          <img class='imleftbtn imleftbtn2 b' src="../../assets/img/f73.png" alt=""  v-if="imIndex<=0"/>
          <img class='imrigbtn b' src="../../assets/img/f74.png" alt="" v-if="imIndex<2" @click="imBtn('rig')"/>
          <img class='imrigbtn imrigbtn2 b' src="../../assets/img/f73.png" v-if="imIndex>=2" alt="" />


        <div class=" dhbox">
          <div class="ul l-start"
          :style="{
            position:'relative',
            left:imIndex==2?'-1770px':(imIndex*-1180)+'px',
            'transition-duration': '1s'
          }">
            <div
                class="features fadeInUp dh2"
                v-for="item in features2"
                :key="item.name"


              >
                <div class="features-icon" v-if="item.icon">
                  <img :src="item.icon" alt="" />
                </div>
                <div class="features-name" v-if="item.name">{{ item.name }}</div>
                <p class='features-txt' v-if="item.txt">{{item.txt}}</p>
              </div>
          </div>


        </div>



        </div>

       </div>
      </div>




      <div class=" scenes-wrap scenes-wrap3 scenes-wrap4" id="scenes-wrap">

        <div
          class="scenes-title survey-title fadeOutUp"
          ref="animate6"
          :class="{ fadeInUp: animateData.animate6 }"
        >
          业务合作伙伴
        </div>


         <div class="scenes2 fadeOutUp" style='margin-bottom:0px'
         ref="animate6"
          :class="{ fadeInUp: animateData.animate6   }">
          <img class='simg ' :style="{  transitionDelay: '0.1s', }" src="../../assets/img/f55.png" alt="" />
        </div>

      </div>


    </div>
  </template>

  <script>
  import { isElementNotInViewport } from "../../utils/index.js";
  export default {
    data() {
      return {
        isup:false,
        istwo:true,
        istree:true,
        imIndex:0,
        navList: [
        {
            name: "标识解析系统",
            icon: require("../../assets/img/f7.png"),
          },
          {
            name: "应用服务系统",
            icon: require("../../assets/img/f8.png"),
          },
          {
            name: "运维监管系统",
            icon: require("../../assets/img/f9.png"),
          },
          {
            name: "工业智脑",
            icon: require("../../assets/img/f10.png"),
          },
          {
            name: "数据交易系统",
            icon: require("../../assets/img/f11.png"),
          },

        ],
        navData:[
            {title:'标识解析系统简介',img:require("../../assets/img/f12.png"),txt:'标识解析系统作为蜂筑平台的基础系统，利用标识解析系统赋予机器、设备、产品，乃至数字对象 “身份证”，打通标识背后的数据链，贯通物理世界和数字空间，实现对企业的数字化赋能。',list:[
            {title:"标识注册、解析和管理能力",img:require("../../assets/img/f17.png"),txt:"注册解析+标识管理，实现全面标识管理"},
            {title:"多层级平台对接能力",img:require("../../assets/img/f18.png"),txt:"国家级平台+第三方服务平台，实现数据互联互通"}
           ]},
           {title:'应用服务系统简介',img:require("../../assets/img/f13.png"),txt:'专业的标识解析应用集合，针对标识解析典型场景在通用商城中即可找到满足企业发展需求的标识解析应用，快速解决企业数字化转型遇到的问题。针对企业的个性化需求，专业的解决方案团队通过实地调研给出合适的解决方案，助力企业标识解析应用建设。同时提供相关开发接口供有开发能力的企业进行标识应用开发。',list:[
            {title:"快速获取标识解析应用能力",img:require("../../assets/img/f19.png"),txt:"典型场景+SAAS化部署，帮助企业快速构建标识应用"},
            {title:"专业个性化解决方案能力",img:require("../../assets/img/f20.png"),txt:"专业团队+现场调研，全面满足企业的个性化需求"},
            {title:"应用开发能力",img:require("../../assets/img/f21.png"),txt:"技术支持+标准化接口，直接对接开发团队定制开发标识应用"}
           ]},
           {title:'运维监管系统简介',img:require("../../assets/img/f14.png"),txt:'运维监管系统通过与行业二级节点和企业三级节点对接打通，进行数据汇聚、管理，形成面向全网的MA标识解析系统运行监测能力，为企业和政府监管部门提供统一的运维监管服务，更加直接了解标识解析业务发展情况。',list:[
            {title:"运维管理能力",img:require("../../assets/img/f22.png"),txt:"后期系统自动升级、数据库管理、运维工单管理、系统管理、日志管理"},
            {title:"状态监管能力",img:require("../../assets/img/f23.png"),txt:"了解标识解析二三级节点运行状态、标识解析应用服务运行状态"},
            {title:"服务托管能力",img:require("../../assets/img/f24.png"),txt:"提供系统服务托管、帮助中小企业提供服务器资源"}
           ]},
           {title:'工业智脑简介',img:require("../../assets/img/f15.png"),txt:'随着接入蜂筑平台企业的数量增加，标识解析生态的逐步建立，针对不同行业，在数据汇聚之后，通过数据分析，利用人工智能技术分析行业发展现状，以及行业未来发展趋势，形成专业的行业分析报告以及相关行业数字产品。',list:[
            {title:"行业数据分析能力",img:require("../../assets/img/f25.png"),txt:"行业发展现状分析"},
            {title:"行业智能预测能力",img:require("../../assets/img/f26.png"),txt:"精准预测行业未来发展趋势"},
            {title:"行业风险预警能力",img:require("../../assets/img/f27.png"),txt:"分析行业发展现状以及未来发展趋势，准确指出行业发展未来面临的潜在风险"}
           ]},
           {title:'数据交易系统简介',img:require("../../assets/img/f16.png"),txt:'通过数据挖掘，数据清洗等技术手段，结合标识解析统一编码的优势，按照数据交易市场的要求，对数据处理之后进行数据交易。行业内不同企业根据自身实际需求，购买或销售数据，利用数据促进自身技术、市场、服务质量的提升。',type:true,list:[
            {title:"数据挖掘能力",img:require("../../assets/img/f28.png"),txt:"在海量数据中找到对于行业内其他企业具有价值的数据",
            title2:"数据确权能力",img2:require("../../assets/img/f29.png"),txt2:"明确企业数据归属权"},
            {title:"区块链能力",img:require("../../assets/img/f30.png"),txt:"利用区块链技术保证企业数据安全，形成专有的数据指纹",
            title2:"数据交易能力",img2:require("../../assets/img/f31.png"),txt2:"对接不同数据交易所，为企业数据创造实际价值"},
           ]},
        ],
        product: [
          {
            name: "3D虚拟模型",
            icon: require("../../assets/images/product-icon_6.png"),
            animate: "animate3",
            delay: 0.1,
          },
          {
            name: "物联网感知",
            icon: require("../../assets/images/product-icon_7.png"),
            animate: "animate4",
            delay: 0.2,
          },
          {
            name: "三维可视化智能管控平台",
            icon: require("../../assets/images/product-icon_8.png"),
            animate: "animate5",
            delay: 0.3,
          },
        ],
        features: [
         {
            name: "重型设备制造行业",
            icon: require("../../assets/img/f35.png"),
            animate: "animate8",
            delay: 0.1,
          },
          {
            name: "能源行业",
            icon: require("../../assets/img/f36.png"),
            animate: "animate7",
            delay: 0.2,
          },
          {
            name: "型材建筑行业",
            icon: require("../../assets/img/f37.png"),
            animate: "animate12",
            delay: 0.3,
          },
          {
            name: "装配式建筑行业",
            icon: require("../../assets/img/f38.png"),
            animate: "animate12",
            delay: 0.4,
          },
          {
            name: "制冷设备制造行业",
            icon: require("../../assets/img/f39.png"),
            animate: "animate12",
            delay: 0.5,
          },

        ],
        features2: [
         {
            name: "协同生产",
            txt:"利用标识记录产品信息，通过标识解析共享产品生产信息，促进不同企业之间信息数据的互联互通，提高产品质量、生产效率和企业竞争力",
            icon: require("../../assets/img/f49.png"),
            animate: "animate20",
            delay: 0,
          },
          {
            name: "一物一码",
            txt:"利用标识作为产品的唯一标识码，通过扫码获取标识对应产品信息，可以实现产品防伪追溯、防窜货等功能，建立基于唯一标识的多应用模式",
            icon: require("../../assets/img/f50.png"),
            animate: "animate21",
            delay: 0.1,
          },
          {
            name: "产品全生命周期管理",
            txt:"通过标识打通不同系统间的数据壁垒，帮助企业实现业务环节信息的对接与互通，实现设计、生产、市场、售后信息数字化 ",
            icon: require("../../assets/img/f51.png"),
            animate: "animate22",
            delay: 0.2,
          },
          {
            name: "设备故障预测及健康管理",
            txt:"利用标识解析技术，通过规范行业设备相关编码规范，实现各行各业、各类企业通过唯一的标识码即可实现互联互通和设备远程运维及预测性维护",
            icon: require("../../assets/img/f52.png"),
            animate: "animate23",
            delay: 0.3,
          },




          {
              name: "数字资产共享与交付",
              txt:"将企业内人员信息、设备的身份信息、设计图纸、工艺流程图、说明书、安装维修手册等虚拟文档，通过标识解析技术赋予唯一标识，进行数字化交付。",
              icon: require("../../assets/img/f67.png"),
              animate: "animate23",
              delay: 0,
            },
            {
              name: "供应链优化管理",
              txt:"配件、物流、整机企业通过唯一标识在标识解析平台解析、查询，解决了企业间因信息不对称引起的生产效率低下问题，实现优化供应链管理。",
              icon: require("../../assets/img/f68.png"),
              animate: "animate23",
              delay: 0.1,
            },
            {
              name: "安全生产立体监控",
              txt:"基于统⼀编码和解析服务，在不同时间、空间下，实现对人、机、物的全面管控，制定更加高效、低成本的安全方案。",
              icon: require("../../assets/img/f69.png"),
              animate: "animate23",
              delay: 0.2,
            },
            {
              name: "防伪防窜货",
              txt:"基于标识的防伪功能与标签防伪技术相结合，采用一物一码技术，让用户通过扫码判断产品真伪，进一步了解产品信息，提升企业品牌信任度。",
              icon: require("../../assets/img/f70.png"),
              animate: "animate23",
              delay: 0.3,
            },
          {
            name: "产品追溯",
            txt:"利用标识解析技术，通过对物品赋予唯一防篡可信的标识码，可实现产品全生命周期追溯和产品生产、物流、销售、服务全过程精细化管理。",
            icon: require("../../assets/img/f71.png"),
            animate: "animate23",
            delay: 0,
          },
          {
            name: "认证授权",
            txt:"利用标识赋码实现统一设备身份认证以及可信数据传输，从源头上保证工业数据的合法性和安全性，服务数据流通，实现万物互联。",
            icon: require("../../assets/img/f72.png"),
            animate: "animate23",
            delay: 0.1,
          }
        ],

        scenes: [
          {
            name: "自动化",
            txt:"可一站式实现界面、接口、移动端自动化测试",
            icon: require("../../assets/img/robot_1.png"),
            animate: "animate9",
            delay: 0.1,
          },
          {
            name: "高覆盖",
            txt:"可自动化生成高覆盖的测试用例",
            icon: require("../../assets/img/robot_2.png"),
            animate: "animate10",
            delay: 0.2,
          },
          {
            name: "更方便",
            txt:"可脚本与数据分离，便于维护和共享",
            icon: require("../../assets/img/robot_3.png"),
            animate: "animate11",
            delay: 0.3,
          },
          {
            name: "更高效",
            txt:"可与其他系统集成，支持二次开发和私有化部署",
            icon: require("../../assets/img/robot_4.png"),
            animate: "animate24",
            delay: 0.4,
          },
        ],
         scenes2: [

          {
            name: "数据拥有方(甲方)",
            title:"数据拥有方(甲方)，自身拥有大量数据，需要对自身数据进行管理。如：银行、证券机构、电信运营商、工业企业、政务数据中心等",
            bg: require("../../assets/img/dcmm_21.png"),
            icon: require("../../assets/img/dcmm_19.png"),
            animate: "animate21",
            delay: 0.1,
          },
          {
            name: "信息技术服务方(乙方)",
            title:"信息技术服务方(乙方)，自身并不拥有大量数据但对外输出数据管理的能力和相关服务。如：数据开发运营商数据中台技术提供商、数据管理技术解决方案提供商。",
            bg: require("../../assets/img/dcmm_21.png"),
            icon: require("../../assets/img/dcmm_20.png"),
            animate: "animate22",
            delay: 0.2,
          }
        ],
        jList:[
            {name:"产品售后"},
            {name:"产品质量追溯"},
            {name:"供应链生产协同"},
            {name:"工程废料管理"},
            {name:"工业大脑"},
            {name:"安环应用"},
        ],
        jData:[
            {title:"产品售后解决方案",img:require("../../assets/img/f40.png"),txt:"通过对出厂产品赋予唯一标识，当消费者需要售后服务时，扫描产品标识码，联系原厂提供售后统一服务。同时原厂可以通过标识收集到相关用户售后需求信息，进而改进产品生产工艺。",list:[
            {title:"行业现状",txt:"产品售后市场往往采用的是线下记录的方法，包括售后维修以及定期养护，生产厂家对产品售后情况的了解有限，无法准确收集到用户的产品使用情况，不能提供专业的原厂售后服务，导致售后服务市场流失"},
            {title:"行业痛点",txt:"产品没有统一提供售后服务的渠道，消费者无法直接联系到原厂，导致去找第三方提供售后服务，造成售后服务质量下降，影响产品使用体验"},
            {title:"服务效果",txt:"为用户提供官方售后渠道。售后数据改进生产工艺。产品养护数据为厂家提供产品长期使用数据，进而优化生产工艺"},
            // {title:"具体案例",txt:"海螺门窗售后服务系统"},
          ]},
          {title:"产品质量追溯解决方案",img:require("../../assets/img/f41.png"),txt:"通过对生产的每一个产品赋予唯一的身份标识，对产品从生产到销售的每个业务流程的关键信息，在标识上进行信息追加，最终消费者可以通过扫码了解产品在各流程所产生的相关信息，实现质量追溯的效果。",list:[
            {title:"行业现状",txt:"消费者在买到产品之后，当产品出现质量问题时，往往无法了解产品生产、物流、销售流程，企业也无法发现问题产品在哪个环节出现了质量问题，导致无法定位问题出现节点以及产生原因。"},
            {title:"行业痛点",txt:"生产信息不明确。业务环节较多，各业务信息不透明。问题产品无法精准定位问题点。"},
            {title:"服务效果",txt:"产品信息透明。精准定位问题产品问题点。提升产品竞争力。"},
            // {title:"具体案例",txt:"冰山集团制冷热设备质量追溯系统"},
          ]},
          {title:"供应链生产协同解决方案",img:require("../../assets/img/f42.png"),txt:"对于生产备件来说，通过对主要生产企业的ERP、MES、营销系统内部数据编码进行产业链上共享，备件提供企业可以看到具体备件需求清单，对需求量大的备件加大备货，暂无需求的备件减少备件，降低库存压力。",list:[
            {title:"行业现状",txt:"复杂设备生产制造过程中，同一厂家往往不能生产设备全部配件，需要从不同厂家采购各种配件，之后进行组装生产，整个生产过程需要多个厂家沟通协商设备配件供货时间，整体生产效率偏低。"},
            {title:"行业痛点",txt:"多个厂家共同生产同一设备过程中，涉及到配件备件、技术共享、开发资料共享等问题，不同企业数据标准各不相同，信息共享存在困难。"},
            {title:"服务效果",txt:"行业企业共享数据可以缩减库存、提高配件交付效率、提升产品交付效率、提高供应商粘性、提高供应链稳定性，并降低生产风险。"},
            // {title:"具体案例",txt:"中联重科产业链同频备件应用"},
          ]},
          {title:"工程废料管理解决方案",img:require("../../assets/img/f43.png"),txt:"对于废料堆放区域利用标识解析分区进行赋码，标识信息包括废料信息，废料堆放区域信息等，利用统一的数据标准，通过废料管理平台向政府监管部门报送，同时对外发送废料信息，帮助废料需求企业进行对接。",list:[
            {title:"行业现状",txt:"建筑行业等相关工程行业在生产过程中会产生大量业务废料，废料处理往往没有统一管理，政府监管存在困难，废料共同处理存在难度。"},
            {title:"行业痛点",txt:"废料缺少统一管理渠道，政府废料监管存在困难，废料资源共享缺乏信息平台。"},
            {title:"服务效果",txt:"加速废料流通，促进废料交易市场发展。标准化的废料信息上报，加强政府废料监管。废料统一管理，减少环境污染。"},
            // {title:"具体案例",txt:"远大住工废料管理应用"},
          ]},
          {title:"工业大脑解决方案",img:require("../../assets/img/f44.png"),txt:"通过标识解析技术，将行业内大中小企业数据汇聚到工业大脑服务平台，进行分析利用。包括产业链图谱，产业链经济监测，产业能耗分析，产业亩均效益分析，产业知识汇聚，产业通讯录，企业大脑，产业预测分析，产业对标分析等功能模块。",list:[
            {title:"行业现状",txt:"政府侧对于行业发展缺少专业化的监管平台。行业政策制定缺乏依据。"},
            {title:"行业痛点",txt:"行业不同企业业务标准不同，导致政府无法利用企业数据进行大规模数据分析助力企业发展，行业发展。"},
            {title:"服务效果",txt:"促进行业数字化、智能化转型。提高行业核心竞争力。加快行业创新发展。促进行业协同发展。推动行业可持续发展。"},
            // {title:"具体案例",txt:"合肥政府工业大脑项目"},
          ]},
          {title:"安环应用解决方案",img:require("../../assets/img/f45.png"),txt:"针对不同的安环场景按需利用标识解析对人、物、环境等对象进行赋码，利用摄像头或者传感器对环境进行实时监测，根据监测数据，融合人员管理系统、通信等系统数据，利用人工智能等手段实现安全预警，规范作业流程，降低安全风险。",list:[
            {title:"行业现状",txt:"传统安环应用只是单独建设视频监控系统，依靠视频监控对图像进行分析来进行安全管理。"},
            {title:"行业痛点",txt:"安环应用功能越全面则需要多系统业务数据相互配合，而各个系统间数据标准各不相同，系统数据融合存在困难。"},
            {title:"服务效果",txt:"保护员工人身安全。降低生产成本和风险。提高生产效率。"},
            // {title:"具体案例",txt:"中联重科工厂内部安环应用"},
          ]},
        ],
        activeName:"产品售后",
        activeIndex:0,
        islev:true,
        idx:0,
        animateData: {
          animate: false,
          animate1: false,
          animate2: false,
          animate3: false,
          animate4: false,
          animate5: false,
          animate6: false,
          animate7: false,
          animate8: false,
          animate9: false,
          animate10: false,
          animate11: false,

        },
      };
    },
    mounted() {
      this.scrollToTop();
      // 监听滚动事件
      window.addEventListener("scroll", this.scrollToTop);
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.scrollToTop);
    },
    methods: {
        imBtn(n){
          if(n=='left'){

              if( this.imIndex<=0){
                this.imIndex=0
              }else{
                this.isup=false
                this.imIndex-=1
                setTimeout(()=>{
                  this.isup=true
                },500)
              }
          }else{
            if(this.imIndex>=2){
              this.imIndex=2
            }else{
              this.isup=false
              this.imIndex+=1
              setTimeout(()=>{
                  this.isup=true
                },500)

            }
          }
        },
        navFn(itm,index){
            this.idx=index
        },
        handleClick(tab, event) {
            this.activeIndex =tab.index
            console.log(tab, event);
        },
        navHandle(id) {
            let target = document.getElementById(id);
            target.scrollIntoView();
        },
        // 滚动事件
        scrollToTop() {

            for (let i in this.$refs) {
            if( !isElementNotInViewport(this.$refs[i])) console.log( !isElementNotInViewport(this.$refs[i]),i)
            !isElementNotInViewport(this.$refs[i])
                ? (this.animateData[i] = true)
                : (this.animateData[i] = false);

                if(i=='animate2' && !isElementNotInViewport(this.$refs[i])){
                    setTimeout(()=>{
                    this.islev = false

                    },1000)
                }
                if((i=='animate30'||i=='animate31'||i=='animate32'||i=='animate33') && !isElementNotInViewport(this.$refs[i])){
                    setTimeout(()=>{
                    this.istree = false

                    },1000)
                }
                if(i=='animate2' && isElementNotInViewport(this.$refs[i])){
                    this.islev = true
                }
                if((i=='animate30'||i=='animate31'||i=='animate32'||i=='animate33') && isElementNotInViewport(this.$refs[i])){
                    this.istree = true
                }
                if(i=='animate4' && !isElementNotInViewport(this.$refs[i])){
                  this.isup = true
                }

            }
        },
        scenesArrowHandle(val) {
            if (val == 1) {
            this.$refs.swiper.prev();
            } else {
            this.$refs.swiper.next();
            }
        },
    },
  };
  </script>

  <style lang="less" scoped>
  .banner {
      width:100%;
      height:433px;
      background: url(../../assets/img/f1.png) no-repeat center center;
      background-size:1920px 433px;
      .h3{
          position: relative;
          top:130px;
          width:1180px;
          margin: 0px auto;
      }
      h3{
          width: 578px;
          height: 45px;
          font-size: 34px;
          color: #FFFFFF;
          line-height: 45px;
          width:1180px;
          font-weight: 400;
          margin: 0px auto;
          position: relative;
          top:160px;
      }
      p{
          height: 19px;
          font-size: 14px;
          color: rgba(255, 255, 255, .49);
          line-height: 19px;
          width:1180px;
          margin: 0px auto;
          position: relative;
          top:182px;

      }
  }

  .scenes {
    margin-top: 38px;

    .scenes-item {
      width: 1181px;
      height:184px;
      background:url(../../assets/img/productRobot_17.png) no-repeat;;
      background-size:100% 100%;
      h3 {
          height: 26px;
          font-size: 26px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 26px;
          text-align: center;
          padding-top:84px;
      }


    }
  }
  .energy-title{
    text-align: center;
  }
  .survey-title{
    height: 37px;
    font-size: 28px;
    color: #414B59;
    line-height: 37px;
    position: relative;
    top:-23px;
  }
  .features-title{
    font-size: 28px;
    color: #FFFFFF;
    line-height: 37px;
    position: relative;
    top:-23px;
  }
  .features-wrap{
    height: 479px;
    background: #396BE3;

  }
  .features-wrap3{
    height: 377px;
    background: #fff;
    .features-title{
        font-size: 28px;
        color: #414B59;
        line-height: 37px;
        position: relative;
        top:-23px;
    }
    .features-box{


      .features-name{
        height: 21px;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #414B59;
        line-height: 21px;
      }
      .features-txt{
        width: 221px;margin:0 auto;
        height: 75px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #95989D;
        line-height: 25px;
        margin-bottom:10px;
      }
      .features-icon{
        height:86px;
      }
    }
    .features-content{

    }
    img{
      transform: scale(1);
      transition-property:all;
      transition-duration:0.3s;
    }
    .sizehove:hover{
      img{
        transform: scale(1.1);

      }
    }

  }
  .features{
    width:284px !important;
    padding:0;
    .features-icon{

    }
    .features-name{
      margin-top: 16px !important;
      height: 48px;
      color: #FFFFFF;
    }
    .features-txt{
      height: 90px;
      font-size: 14px;
      color: rgba(255, 255, 255, .66);
      line-height: 30px;
    }
  }

  .survey{
    padding:50px 0;
  }
  .scenes .bomlist{
    width: 280px;
    height: 297px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 9px rgba(132,181,255,0.08);
    border-radius: 4px;
    padding-top:40px;
    img{
      width:78px;
      margin: 0 auto;
    }
    .yytitle{
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      color: #414B59;
      line-height: 26px;
      margin-top:17px;
    }
    .yytext{
      text-align: center;
      width: 230px;
      height: 60px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #999999;
      line-height: 24px;
      margin:0 auto;
      margin-top:11px;

    }
  }
  .yytxt{
    font-size: 18px !important;
    color: #909399 !important;
  }
  .toptxt{
      width: 100%;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #909399;
      line-height: 32px;
      text-align: center;
      span{
          color:rgba(65, 75, 89, 1);
          font-size:20px;
      }
  }
  .cp-item{
    width:384px;
    height:507px;
    text-align: center;
    padding-top:142px;
    background:#396BE3;
    .cpimg{

    }
    .cph3{
      height: 30px;
      font-size: 18px;
      color: #FFFFFF;
      line-height: 24px;
      margin:24px 0 7px 0;

    }
    .cptxt{
        width: 280px;
        height: 48px;
        font-size: 14px;
        color: rgba(255, 255, 255, .9);
        line-height: 24px;
        margin: 0 auto;
    }
  }
  .cp-item:hover{
      background:#396BE3;
      background-image:none !important;
    }
  // 产品简介图
  .topbox{
      width:1092px;
      margin:0 auto;
      margin-bottom:50px;
      text-align: center;
      .topleft{
          width: 603px;
          height: 603px;
        //   background: url(../../assets/img/productRobot_2.png) no-repeat;
            margin:23px auto;
        opacity: 1;
          position: relative;
          img{
              position: absolute;
          }
          .topleftimg1{
              top:280px;
              right:-13px;
          }
          .topleftimg2{
              top:280px;
              left:-13px;
          }
          .topleftimg3{
              top:-57px;
              left:154px;
          }
      }
  }
  .topbox2{
      .topleft{
            opacity: 0;
          .topleftimg1{
              top:210px;
              right:57px;
              opacity: 0;
          }
          .topleftimg2{
              top:210px;
              left:57px;
              opacity: 0;
          }
          .topleftimg3{
              top:43px;
              left:154px;
              opacity: 0;
          }
      }
  }
  .tran{
      transition: all 1s ease-in;
      -webkit-transition:all 1s ease-in;
    }
    .scenes .scenes-item img:hover {
      transform: scale(1) !important;
    }
    .scenes .scenes-item:hover {
      transform: scale(1.1) !important;
    }

// 等级
.levebox{
    width:882px;
    height:507px;
    margin:0 auto;
    overflow: hidden;
    .imgdiv2,.imgdiv3,.imgdiv4,.imgdiv5{
        position: relative;
        margin-top:-40px;
    }

    .limgv{
        width:61px;
        height:65px;
    }
    .limg02{
        position: relative;
        top:-30px;
        left:235px;
    }
    .limg03{
        position: relative;
        top:-30px;
        left:165px;
    }
    .limg04{
        position: relative;
        top:-30px;
        left:95px;
    }
    .limg05{
        position: relative;
        top:-30px;
        left:20px;
    }
    .limg5{
        margin-left:-70px;
        margin-top:5px;
    }

    .limg4{
        margin-left:5px;
        margin-top:5px;
    }
    .limg3{
        margin-left:80px;
        margin-top:5px;
    }

    .limg2{
        margin-left:145px;
        margin-top:5px;

    }
    .limg1{
        margin-left:280px;
        position: relative;
    }


}
.leved{
  .limg1,.limg2,.limg3,.limg4,.limg5{
    position: relative;
        opacity: 0;
    }
    .limg02,.limg03,.limg04,.limg05{
      position: relative;
        opacity: 0;
    }
    .limgv{
      opacity: 0;
    }
}
.scenes-wrap{
  padding-top:30px;
}
.scenes-wrap2{
  background: #F6F8FD;
  position: relative;
  .bg{
    background: url(../../assets/img/dcmm_22.png) no-repeat;
    background-size:100% 100%;
    width:1920px  ;
    height:326px;
    position: absolute;
    top:0;
    left:0;
    z-index: -1;
  }
  .cpt{
    height: 32px;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 32px;
  }
  .survey-title{
    color: #FFFFFF;
  }
  .cp-4{
    width: 581px;
    height: 249px;
    box-shadow: 0px 0px 20px 10px rgba(0,0,0,0.05);
    border-radius: 8px;
    background: #fff;
    z-index: 1;
    position: relative;
  }
  .bg2{
    background: url(../../assets/img/dcmm_21.png) no-repeat;
    background-size:99px 122px;
    width:99px;
    height:122px;
    position: absolute;
    top:0;
    left:452px;
  }
  .cpimg{
    padding:19px 0 6px 39px;
  }
  .cph3{
    height: 31px;
    font-size: 24px;
    font-family: MicrosoftYaHei;
    color: #414B59;
    line-height: 31px;
    margin:0 0 9px 27px;
  }
  .cptxt{
    width: 533px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #909399;
    line-height: 28px;
    margin-left:27px;
  }
  .topbox {
    width: 1092px;
    margin: 0 auto;
    margin-bottom: 25px;
    margin-top: -7px;
    text-align: center;
  }
  .scenes {
      margin-top: 0;
  }
}
.scenes-wrap3{
  height:673px;
  background:#fff;
  width: 100%;
  .topbox{
    margin-bottom:30px;
  }
  .scenes2{
    text-align: center;
    .h3{
      text-align: center;

    }
    .simg{
      width: 1250px;
      margin: 0 auto;
      align-items: center;
      .disc{
        width: 400px;
        font-size: 18px;
        color: #9B9FA6;
        line-height: 34px;
        text-align: left;
        font-family: MicrosoftYaHei;
        .fontB{
          font-size: 18px;
          color: #6A6F78;
          line-height: 34px;
          font-weight: bold;
        }
      }
      img{
        width: 800px;
      }
    }
    .p{
      width: 1160px;
      height: 24px;
      font-size: 18px;
      font-family: MicrosoftYaHei;
      color: #999999;
      line-height: 24px;
      margin:0 auto;
      text-align: left;
      margin-top:-30px;
    }
  }


}
.h852{
  height:852px;
  overflow: hidden;
}
.scenes-wrap4{
  height:320px;
  padding-top:70px;
  .topbox {
    margin-top:-20px;
  }
  .scenes2{
    .simg{
      margin-top:22px;
    }

  }
}

.hidden{
  overflow: hidden;
}


// index
.survey1{
    height:828px;
    background: url(../../assets/img/f4.png) no-repeat center center;
    background-size:1920px 100%;
    position: relative;
    overflow: hidden;

}

.nr{
    background: url(../../assets/img/f75.png) no-repeat center center;
    background-size:1920px 100%;
    min-height:754px;
    padding:10px 0!important;
    .topbox{
        text-align: left;
    }
    .ibox{
        margin-top:20px;
        margin-bottom:30px;
        .left{
            width: 818px;
            min-height: 521px;
            background: #FFFFFF;
            box-shadow: 0px 0px 10px 8px rgba(144,144,144,0.03);
            padding:9px 0 0 38px;
            .h3{
                font-size: 26px;
                font-family: MicrosoftYaHei;
                color: #414B59;
                line-height: 35px;
                margin-top:2px;
                font-weight: 400;
            }
            .p{
                width: 725px;
                font-size: 14px;
                font-family: MicrosoftYaHei;
                color: #909399;
                line-height: 28px;
                margin-top:4px;
                padding-right:20px;
            }
            .limg{
                width:57px;
                height:68px;
                margin-left:4px;
            }
            .h4{
                height: 26px;
                font-size: 20px;
                font-family: MicrosoftYaHei;
                color: #414B59;
                line-height: 26px;
                position: relative;
                margin:27px 0 10px 0;
                z-index: 1;
                .xian{
                    width: 80px;
                    height: 8px;
                    background: #428DE8;
                    position: absolute;
                    left:0px;
                    bottom:3px;
                    z-index: -1;
                    opacity: 0.5;
                }
            }
            .lbox{
              width:732px;
                .lboxrow{
                    height:126px;
                    width:712px;
                    padding-top:27px;
                    .listimg{
                        width:71px;
                        height: 71px;
                        margin-right:13px;
                        position: relative;
                        top:-5px;
                    }
                    .lrig{
                        .lh4{
                            height: 24px;
                            font-size: 18px;
                            font-family: MicrosoftYaHei;
                            color: #414B59;
                            line-height: 24px;
                            font-weight: 400;
                        }
                        .lp{
                            height: 30px;
                            font-size: 14px;
                            font-family: MicrosoftYaHei;
                            color: #95989D;
                            line-height: 30px;
                            margin-top:3px;
                        }
                    }
                }
                .lboxrowborder{
                    border-top: 1px dashed #E4E4E4;
                }
                .newrow{
                    width:340px;
                    padding-right:20px;
                    .lp{
                        line-height: 24px !important;
                    }
                }
            }
        }
        .rig{
            height:497px;
            width:347px;
            border-right:1px solid #CDD3DE;
            .rig-row{
                height:73px;
                position: relative;
                right: -2px;
                display: flex;
                align-items: center;
                margin-top:20px;
            }
            .active{
                border-right: 4px solid #4998ED;
                .txt{
                    color: #414B59;
                }

            }
            .iimg{
                width:43px;
                height:51px;
                margin-left:64px;
            }
            .txt{
                width: 144px;
                height: 31px;
                font-size: 24px;
                font-family: MicrosoftYaHei;
                color: #888F9A;
                margin-left:12px;
            }


        }
    }
  }
.dj{
    height: 680px;
    background: #F6F9FD;
    .topbox{
        width:100%;
    }
    /deep/.el-tabs__nav-wrap::after{
        background-color:inherit;
    }
    .jhead{
        width:680px;
        margin:0 auto;
    }
    .jcont{
        text-align: left;
        height: 431px;
        // background: linear-gradient(270deg, #83CDF8 0%, #3E76E0 100%);
        background: url(../../assets/img/f46.png) no-repeat center center;
        background-size:1426px 100%;
        background-position-x: 0;
        .cont{
            width:1092px;
            margin:0 auto;
            padding-top:68px;
            .left{
                .jimg{
                    width:87px;
                    height:104px;
                }
                .h4{
                    height: 31px;
                    font-size: 24px;
                    font-family: MicrosoftYaHei;
                    color: #FFFFFF;
                    line-height: 31px;
                    margin-top:4px;
                    position: relative;
                    z-index: 1;
                    display: inline-block;
                    .xian{
                        width: 100%;
                        height: 8px;
                        background: #42BFE8;
                        position: absolute;
                        left:0px;
                        bottom:3px;
                        z-index: -1;
                    }
                }
                .jp{
                    width: 310px;
                     font-size: 14px;
                    font-family: MicrosoftYaHei;
                    color: #FFFFFF;
                    line-height: 26px;
                    margin-top:19px;

                }
            }
            .rig{
                width: 819px;
                min-height: 290px;
                background: rgba(255,255,255,.92);
                box-shadow: 0px 0px 8px 8px rgba(0,0,0,0.04);
                border-radius: 6px;
                margin-left: 26px;
                padding-top:25px;
                .rbox{
                    margin:0 20px 0 48px;
                    padding-top:10px;
                    width: 751px;
                    height: 84px;
                    .dian{
                        min-width: 11px;
                        height: 11px;
                        background: #4078E0;
                        border:3px solid #d9ebfb;
                        border-radius: 50%;;
                        position: relative;
                        top:5px;
                        left: -15px;

                    }
                    .h3{
                        height: 21px;
                        font-size: 16px;
                        font-family: MicrosoftYaHei;
                        color: #414B59;
                        line-height: 21px;
                    }
                    .rp{
                        width: 720px;
                        height: 40px;
                        font-size: 12px;
                        font-family: MicrosoftYaHei;
                        color: #909399;
                        line-height: 20px;
                        margin-top:4px;

                    }

                }
                .xian1{
                        border-top:1px dashed #E4E4E4;
                    }
            }
        }
    }
}
.features-wrap4{
  background: url(../../assets/img/dcmm_22.png) no-repeat;
    background-size:1920px 100%;
    height:540px;
  .features {
    min-width: 284px;
    height: 312px;
    background: #FFFFFF;
    border-radius: 4px;
    .features-txt{
      color: #95989D;
      padding:0 20px;
      font-size: 14px;
      line-height: 25px;
    }
    .features-name{
      color: #414B59;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .importre{
    position: relative;
    .imleft{
      position: absolute;
      top:-90px;
      left:-200px;
    }
    .imrig{
      position: absolute;
      bottom:30px;
      right:-90px ;
    }
    .imleftbtn{
      position: absolute;
      top:124px;
      left:-70px ;
      width:20px;
      height:36px;
      transform: rotate(180deg);
    }
    .imleftbtn2{
      transform: rotate(0deg);
    }
    .imrigbtn{
      position: absolute;
      top:124px;
      right:-70px;
      width:20px;
      height:36px;
    }
    .imrigbtn2{
      transform: rotate(180deg);
    }
  }
}
.topimgbox{
  .im1{
      background: url(../../assets/img/f57.png) no-repeat bottom center;
      background-size:773px 67px;
      width:773px;
      height:120px;
      padding:0 10px;
      margin:0 auto;
      .imi{
        width: 120px;
        height:92px;
        position: relative;
      }
      .opt.imi{
        opacity: 0;
        top:-10px;
    }
  }
  .im2{
      background: url(../../assets/img/f62.png) no-repeat bottom center;
      background-size:943px 158px;
      width:943px;
      height:180px;
      margin:0 auto;
      img{
        width:126px;
        height:128px;
        position: relative;
        margin-top:35px;

      }
      .img2_1{
        left:-16px;
      }
      .img2_2{
        left:16px;
      }
  }
  .im3{
      width:904px;
      height:102px;
      margin:20px auto;
  }
  .im4{
    width:476px;
      height:67px;
      margin:0 auto;
  }
}

.xhdh{
       animation: myfirst 1.5s 1s linear infinite alternate;
      -webkit-animation: myfirst 1.5s 1s linear infinite alternate; /* Safari 与 Chrome */
    }
    @keyframes myfirst
        {
            from {top: -10px;}
            to {top: -25px;}
        }


.imgpro{
    position: relative;
    left: 7px;
}

.dhbox{
  width:1180px;
  overflow: hidden;
  height: 350px;
  padding-top: 30px;
  position: relative;
  top: -30px;
  .ul{
    width: auto;
    position: relative;
  }
  .dh2{
    margin-right:11px;
    transform: scale(1);
    transition-property:all;
    transition-duration:.3s;
    padding-top:17px;
    .features-name{
      margin-top: -8px !important;
      height: 37px !important;
    }
  }
  .dh2:hover{
    transform: scale(1.02);
  }
}

  </style>
