<template>
    <div>
      <div class="banner">
        <!-- <h3>CRM客户关系管理系统</h3>
        <p>Transformer intelligent testing product based on micro robot</p> -->
          <div  class='h3'>
            <img src='../../assets/img/2024/d_1.png'>
          </div>
      </div>
      <!-- <div class="product-nav-wrap">
        <div class="product-nav">
          <a
            v-for="item in navList"
            :key="item.name"
            @click="navHandle(item.url)"
            >{{ item.name }}</a
          >
        </div>
      </div> -->
      <div class="survey" id="survey" ref="animate14" style="overflow: hidden;">
         <div
          class="energy-title fadeOutUp"
          ref="animate"
          :class="{ fadeInUp: animateData.animate }"
        >
          <img class='aiOPS_bjimg' src="../../assets/img/productSatellite_18.png" alt="" />
        </div>
        <div
          class="survey-title fadeOutUp"
          ref="animate"
          :class="{ fadeInUp: animateData.animate }"
        >
          产品简介
        </div>
      
              
              <p class='toptxt fadeOutUp'
        ref="animate13"
        :class="{ fadeInUp: animateData.animate13 }"
              >数据产品可信交易平台，采用<span>“可用不可见、可控可计量”</span>的应用模式，利用区块链、智能合约、多方安全计算等技术，构建安全、高效、可信的<span>数据空间</span>，在本地数据不出域的前提下，实现多方、多源、多维数据的隐私计算，以此确保数据报告等<span>数据衍生产品</span>的安全、可信交易。</p>
              <div class='topleft fadeOutUp'   :class="{ fadeInUp: animateData.animate14  }">
                  <img
                    class="fadeOutUp"
                    :class="{ fadeInUp: animateData.animate14   }"
                    v-for="(item,index) in topleftimgList"
                    :Key="index"
                    :style="item.style"
                    :src="item.img"
                    alt=""
                    
                />
                  <!-- <img class='topleftimg2 tran' src="../../assets/img/productRobot_4.png" alt="" />
                  <img class='topleftimg3 tran' src="../../assets/img/productRobot_5.png" alt="" /> -->
              </div>
      </div>
      <div class="features-wrap" id="features-wrap">
         <div
          class="energy-title fadeOutUp"
          ref="animate1"
          :class="{ fadeInUp: animateData.animate1 }"
        >
          <img class='aiOPS_bjimg' src="../../assets/img/productSatellite_20.png" alt="" />
        </div>
        <div
          class="features-title  fadeOutUp"
          ref="animate1"
          :class="{ fadeInUp: animateData.animate1 }"
        >
          核心能力
        </div>
        <div class="features-content" style="flex-wrap: wrap;">
          <div
            class="features fadeOutUp"
            v-for="item in features"
            :key="item.name"
            :style="{
              transitionDelay: item.delay + 's',
            }"
            :class="{ fadeInUp: animateData[item.animate] }"
            :ref="item.animate"
          >
            <div class="features-icon">
              <img :src="item.icon" alt="" />
            </div>
            <div class="features-name">{{ item.name }}</div>
            <p class='features-txt'>{{item.txt}}</p>
          </div>
        </div>
      </div>
       <div class="scenes-wrap" id="scenes-wrap">
          <div
          class="energy-title fadeOutUp"
          ref="animate12"
          :class="{ fadeInUp: animateData.animate12 }"
        >
          <img class='aiOPS_bjimg' src="../../assets/img/productSatellite_19.png" alt="" />
        </div>
        <div
          class="scenes-title survey-title fadeOutUp"
          ref="animate12"
          :class="{ fadeInUp: animateData.animate12 }"
        >
          产品特点
        </div>
  
        <div class="scenes">
          <div
            class="cp-item fadeOutUp"
            v-for="item in scenes2"
            :key="item.name"
            :style="{
              backgroundImage: 'url(' + item.bg + ')',
              transitionDelay: item.delay + 's',
            }"
            :class="{ fadeInUp: animateData[item.animate] }"
            :ref="item.animate"
          >
           <div
            class='fadeOutUp'
           :class="{ fadeInUp: animateData[item.animate] }"
           :ref="item.animate"
            :style="{
              transitionDelay: (item.delay*1+0.3) + 's',
            }">
               <div class='cpimg' :style="`top:${item.top}px;`">
                <img :src="item.icon" alt="" />
              </div>
              <div class='cph3'>{{ item.name }}</div>
              <div class='cptxt'>{{ item.title }}</div>
          </div>
          </div>
        </div>
      </div>
  
      <div class="scenes-wrap" id="scenes-wrap">
          <div
          class="energy-title fadeOutUp"
          ref="animate2"
          :class="{ fadeInUp: animateData.animate2 }"
        >
          <img class='aiOPS_bjimg' src="../../assets/img/productSatellite_21.png" alt="" />
        </div>
        <div
          class="scenes-title survey-title fadeOutUp"
          ref="animate2"
          :class="{ fadeInUp: animateData.animate2 }"
        >
          产品优势
        </div>
  
         <div class="scenes" style='margin-bottom:80px'>
          <div
            class="scenes-item fadeOutUp bomlist"
            v-for="item in scenes"
            :key="item.name"
            :style="{
              transitionDelay: item.delay + 's',
            }"
            :class="{ fadeInUp: animateData[item.animate] }"
            :ref="item.animate"
          >
            <div style="height: 90px;text-align:center">
              <img  :src="item.icon" alt="" style='margin: 0 auto;' />
            </div>
            <div class='yytitle'>{{ item.name }}</div>
            <div class='yytext'>{{ item.txt }}</div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { isElementNotInViewport } from "../../utils/index.js";
  export default {
    data() {
      return {
        istwo:true,
        navList: [
          {
            name: "产品概况",
            url: "survey",
          },
          {
            name: "产品特点",
            url: "features-wrap",
          },
          {
            name: "应用场景",
            url: "scenes-wrap",
          },
        ],
        topleftimgList:[
          {img:require('../../assets/img/2024/t1.png'),style:'top:207px;left:49px; transitionDelay:.6s'},
          {img:require('../../assets/img/2024/t2.png'),style:'top:11px;left:50%;transform: translate(-50%,0);transitionDelay:.6s'},
          {img:require('../../assets/img/2024/t3.png'),style:'top:207px;right:47px;transitionDelay:.6s'},
        ],
        product: [
          {
            name: "3D虚拟模型",
            icon: require("../../assets/images/product-icon_6.png"),
            animate: "animate3",
            delay: 0.1,
          },
          {
            name: "物联网感知",
            icon: require("../../assets/images/product-icon_7.png"),
            animate: "animate4",
            delay: 0.2,
          },
          {
            name: "三维可视化智能管控平台",
            icon: require("../../assets/images/product-icon_8.png"),
            animate: "animate5",
            delay: 0.3,
          },
        ],
        features: [
         {
            name: "衍生产品定制",
            icon: require("../../assets/img/2024/t5.png"),
            bg: require("../../assets/images/features-bg_9.png"),
            animate: "animate8",
            txt:"可针对统计需求、数据范围、输出要求等要素，定制化创建数据衍生产品",
            delay: 0.4,
          },
          {
            name: "订单实时估价",
            icon: require("../../assets/img/2024/t6.png"),
            bg: require("../../assets/images/features-bg_8.png"),
            animate: "animate7",
            txt:"可按照订单需求，实时计算出数据提供方及其数据使用量，并按规则报价",
            delay: 0.3,
          },
           {
            name: "多方安全计算",
            icon: require("../../assets/img/2024/t7.png"),
            bg: require("../../assets/images/features-bg_7.png"),
            animate: "animate6",
            txt:"可通过多方安全计算，统计多方、多源的数据信息，并返回统计结果信息",
            delay: 0.2,
          },
          {
            name: "交易过程追溯",
            icon: require("../../assets/img/2024/t8.png"),
            bg: require("../../assets/images/features-bg_7.png"),
            animate: "animate102",
            txt:"可将数据交易过程中所涉及的数据查询、使用、凭证等关键信息上链记录",
            delay: 0.1,
          },
         
  
        ],
        scenes: [
          {
            name: "原始数据不出域",
            txt:"原始数据可本地存储，数据更安全，大幅降低业务推广门槛",
            icon: require("../../assets/img/2024/t17.png"),
            animate: "animate9",
            delay: 0.1,
          },
          {
            name: "低资源高效计算",
            txt:"支持原始数据本地计算和结果异步回传，计算资源占用更少",
            icon: require("../../assets/img/2024/t18.png"),
            animate: "animate10",
            delay: 0.2,
          },
          {
            name: "数据资源防篡改",
            txt:"可验证数据是否被篡改、污染，以确保数据和计算结果的可信性",
            icon: require("../../assets/img/2024/t19.png"),
            animate: "animate11",
            delay: 0.3,
          },
          
        ],
         scenes2: [
  
          {
            name: "独立的数据空间",
            title:"将业务交易过程与可信数据空间解耦，以确保数据独立性和免篡改",
            bg: require("../../assets/img/2024/t9.png"),
            icon: require("../../assets/img/2024/t13.png"),
            animate: "animate21",
            top:-100,
            delay: 0.1,
          },
          {
            name: "本地化工具应用",
            title:"提供本地化应用工具，全面满足数据上传、用量估算、本地计算能力 ",
            bg: require("../../assets/img/2024/t10.png"),
            icon: require("../../assets/img/2024/t14.png"),
            animate: "animate22",
            top:-100,
            delay: 0.2,
          },
          {
            name: "混合式隐私计算",
            title:"支持多方、多源、多维的原始数据与本地计算结果数据的混合安全计算",
            bg: require("../../assets/img/2024/t11.png"),
            icon: require("../../assets/img/2024/t15.png"),
            animate: "animate23",
            top:-100,
            delay: 0.3,
          },
          {
            name: "数据经多方加密",
            title:"计算所需数据需经平台、需求方双重加密，大幅降低数据泄露风险",
            bg: require("../../assets/img/2024/t12.png"),
            icon: require("../../assets/img/2024/t16.png"),
            animate: "animate23",
            top:-100,
            delay: 0.4,
          },
        ],
  
        animateData: {
          animate: false,
          animate1: false,
          animate2: false,
          animate3: false,
          animate4: false,
          animate5: false,
          animate6: false,
          animate7: false,
          animate8: false,
          animate9: false,
          animate10: false,
          animate11: false,
          animate12: false,
          animate100: false,
          animate101: false,
          animate102: false,
  
        },
      };
    },
    mounted() {
      this.scrollToTop();
      // 监听滚动事件
      window.addEventListener("scroll", this.scrollToTop);
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.scrollToTop);
    },
    methods: {
      navHandle(id) {
        let target = document.getElementById(id);
        target.scrollIntoView();
      },
      // 滚动事件
      scrollToTop() {
        for (let i in this.$refs) {
          !isElementNotInViewport(this.$refs[i])
            ? (this.animateData[i] = true)
            : (this.animateData[i] = false);
  
            // if(i=='animate14' && !isElementNotInViewport(this.$refs[i])){
            //     setTimeout(()=>{
            //       this.istwo = false
  
            //     },700)
            // }
            // if(i=='animate14' && isElementNotInViewport(this.$refs[i])){
            //       this.istwo = true
            // }
        }
      },
      scenesArrowHandle(val) {
        if (val == 1) {
          this.$refs.swiper.prev();
        } else {
          this.$refs.swiper.next();
        }
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .banner {
      width:100%;
      height:433px;
      background: url(../../assets/img/2024/t4.png) no-repeat center center;
      background-size:1920px 433px;
      .h3{
          position: relative;
          top:130px;
          width:1180px;
          margin: 0px auto;
      }
      h3{
          width: 578px;
          height: 45px;
          font-size: 34px;
          color: #FFFFFF;
          line-height: 45px;
          width:1180px;
          font-weight: 400;
          margin: 0px auto;
          position: relative;
          top:160px;
      }
      p{
          height: 19px;
          font-size: 14px;
          color: rgba(255, 255, 255, .49);
          line-height: 19px;
          width:1180px;
          margin: 0px auto;
          position: relative;
          top:182px;
  
      }
  }
  
  .scenes {
    margin-top: 38px;
  
    .scenes-item {
      width: 1181px;
      height:184px;
      background:url(../../assets/img/productRobot_17.png) no-repeat;
      background-size:100% 100%;
      &:hover{
        transition: all 0.1s linear;
        transition-delay: 0s !important;
      }
      h3 {
          height: 26px;
          font-size: 26px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 26px;
          text-align: center;
          padding-top:84px;
      }
  
  
    }
  }
  .energy-title{
    text-align: center;
  }
  .survey-title{
    height: 37px;
    font-size: 28px;
    color: #414B59;
    line-height: 37px;
    position: relative;
    top:-23px;
  }
  .features-title{
    font-size: 28px;
    color: #FFFFFF;
    line-height: 37px;
    position: relative;
    top:-23px;
  }
  .features-wrap{
    height: 498px;
    background: #3E76E0;
  
  }
  .features{
    width:240px !important;
    padding:0;
    margin-top: 15px;
    &:nth-of-type(3n){
      margin-right: 0;
    }
    .features-icon{
  
    }
    .features-name{
      margin-top: 16px !important;
      height: 48px;
      color: #FFFFFF;
    }
    .features-txt{
      height: 90px;
      font-size: 14px;
      color: rgba(255, 255, 255, .66);
      line-height: 30px;
    }
  }
  .scenes .bomlist{
    width: 375px;
    height: 284px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 9px rgba(132,181,255,0.08);
    border-radius: 4px;
    padding-top:40px;
    img{
      width:78px;
      margin: 0 auto;
    }
    .yytitle{
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      color: #414B59;
      line-height: 26px;
      margin-top:17px;
    }
    .yytext{
      text-align: center;
      width: 294px;
      height: 60px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #999999;
      line-height: 24px;
      margin:0 auto;
      margin-top:12px;
  
    }
  }
  .yytxt{
    font-size: 18px !important;
    color: #909399 !important;
  }
  .toptxt{
    width: 1140px;
    height: 128px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #909399;
    line-height: 32px;
    margin:0 auto;
    margin-top:10px;
    text-align: center ;
      span{
          color:rgba(65, 75, 89, 1);
          font-size:20px;
      }
  }
  .cp-item{
    width:289px;
    height:378px;
    text-align: center;
    padding-top:150px;
    background:#396BE3;
    position: relative;
    display: block;
    .cpimg{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .cph3{
      height: 30px;
      font-size: 18px;
      color: #FFFFFF;
      line-height: 24px;
      margin:24px 0 7px 0;
  
    }
    .cptxt{
        width: 224px;
        height: 48px;
        font-size: 14px;
        color: rgba(255, 255, 255, .9);
        line-height: 24px;
        margin: 0 auto;
    }
  }
  .cp-item:hover{
      background:#396BE3;
      background-image:none !important;
    }
  // 产品简介图
 
      .topleft{
        width:875px;
        height:578px;
        margin:0 auto;
          background: url(../../assets/img/2024/d_2.png) no-repeat;
          background-size: 100%;
          position: relative;
          img{
              position: absolute;
              transition-delay: 0.2s;
          }
          .topleftimg1{
              top:280px;
              right:-13px;
          }
          .topleftimg2{
              top:280px;
              left:-13px;
          }
          .topleftimg3{
              top:-57px;
              left:154px;
          }
      }

  .tran{
      transition: all 1s ease-in;
      -webkit-transition:all 1s ease-in;
    }
    .scenes .scenes-item img:hover {
      transform: scale(1) !important;
    }
    .scenes .scenes-item:hover {
      transform: scale(1.1) !important;
    }
  </style>
  