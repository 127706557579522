<template>
  <div>
    <div class="banner">
      <!-- <h3>工业互联网标识解析系统</h3> -->
      <div  class='h3'>
        <img src='../../assets/img/productSatellite_25.png'>
      </div>
      <!-- <p>Visual intelligent monitoring products in non signal area based on satellite</p> -->
    </div>
    <!-- <div class="product-nav-wrap">
      <div class="product-nav">
        <a
          v-for="item in navList"
          :key="item.name"
          @click="navHandle(item.url)"
          >{{ item.name }}</a
        >
      </div>
    </div> -->
    <div class="survey" id="survey">
       <div
        class="energy-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        <img class='aiOPS_bjimg' src="../../assets/img/productSatellite_18.png" alt="" />
      </div>
      <div
        class="survey-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        产品简介
      </div>
      <p class='toptxt fadeOutUp'
        ref="animate14"
        :class="{ fadeInUp: animateData.animate14 }"
      ><span></span>标识解析系统作为工业互联网的基础设施，支持通过赋予机器、设备、产品，乃至数字对象 “身份证”，打通标识背后的数据链，贯通物理世界和数字空间，并通过集成供应链管理、产品溯源、产品全生命周期管理等创新应用，实现对企业的数字化赋能。</p>
      <div class='topimgbox l-between fadeOutUp'
        ref="animate13"
        :class="{ fadeInUp: animateData.animate13 }"
      
      > 
          <img class='img1' src='../../assets/img/p_18.png'>
          <img class='img2 po tran ' :class='{fade1:istwo}' src='../../assets/img/p_19.png'>

          <img class='img3 po tran ' :class='{"fade2 fadeimg1":istree}' src='../../assets/img/p_20.png'>
          <img class='img4 po tran ' :class='{"fade2 fadeimg2":istree}' src='../../assets/img/p_21.png'>
          <img class='img5 po tran ' :class='{"fade2 fadeimg3":istree}' src='../../assets/img/p_22.png'>
          <img class='img6 po tran ' :class='{"fade2 fadeimg4":istree}' src='../../assets/img/p_23.png'>
          <img class='img7 po tran ' :class='{"fade2 fadeimg5":istree}' src='../../assets/img/p_24.png'>
          
      </div>
      
    </div>
    <div class="features-wrap" id="features-wrap">
       <div
        class="energy-title fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
      >
        <img class='aiOPS_bjimg' src="../../assets/img/productSatellite_20.png" alt="" />
      </div>
      <div
        class="features-title  fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
      >
        核心能力
      </div>
      <div class="features-content l-between l-wrap">
        <div
          class="features fadeOutUp"
          v-for="item in features"
          :key="item.name"
          :style="{
            transitionDelay: item.delay + 's',
          }"
          :class="{ fadeInUp: animateData[item.animate] }"
          :ref="item.animate"
        >
          <div class="features-icon">
            <img :src="item.icon" alt="" />
          </div>
          <div class="features-name">{{ item.name }}</div>
          <p class='features-txt'>{{item.txt}}</p>
        </div>
      </div>
    </div>
     <div class="scenes-wrap" id="scenes-wrap">
        <div
        class="energy-title fadeOutUp"
        ref="animate12"
        :class="{ fadeInUp: animateData.animate12 }"
      >
        <img class='aiOPS_bjimg' src="../../assets/img/productSatellite_19.png" alt="" />
      </div>
      <div
        class="scenes-title survey-title fadeOutUp"
        ref="animate12"
        :class="{ fadeInUp: animateData.animate12 }"
      >
        产品特点
      </div>
    
      <div class="scenes">
        <div
          class="cp-item fadeOutUp"
          v-for="item in scenes2"
          :key="item.name"
          :style="{
            backgroundImage: 'url(' + item.bg + ')',
            transitionDelay: item.delay + 's',
          }"
          :class="{ fadeInUp: animateData[item.animate] }"
          :ref="item.animate"
        >
        <div 
        class='fadeOutUp'
         :class="{ fadeInUp: animateData[item.animate] }"
         :ref="item.animate"
          :style="{
            transitionDelay: (item.delay*1+0.3) + 's',
          }">
             <div class='cpimg'>
              <img :src="item.icon" alt="" />
            </div>
            <div class='cph3'>{{ item.name }}</div>
            <div class='cptxt'>{{ item.title }}</div>
        </div>
         
        </div>
      </div>
    </div>

    <div class="scenes-wrap" id="scenes-wrap">
        <div
        class="energy-title fadeOutUp"
        ref="animate2"
        :class="{ fadeInUp: animateData.animate2 }"
      >
        <img class='aiOPS_bjimg' src="../../assets/img/productSatellite_21.png" alt="" />
      </div>
      <div
        class="scenes-title survey-title fadeOutUp"
        ref="animate2"
        :class="{ fadeInUp: animateData.animate2 }"
      >
        应用场景
      </div>
    
      <div class="scenes" style='margin-bottom:80px'>
        <div
          class="scenes-item fadeOutUp"
          v-for="item in scenes"
          :key="item.name"
          :style="{
            transitionDelay: item.delay + 's',
          }"
          :class="{ fadeInUp: animateData[item.animate] }"
          :ref="item.animate"
        >
          <div style="height: 187px">
            <img :src="item.icon" alt="" />
          </div>
          <span class='yytxt'>{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isElementNotInViewport } from "../../utils/index.js";
export default {
  data() {
    return {
      istree:true,
      istwo:true,
      navList: [
        {
          name: "产品概况",
          url: "survey",
        },
        {
          name: "产品特点",
          url: "features-wrap",
        },
        {
          name: "应用场景",
          url: "scenes-wrap",
        },
      ],
      product: [
        {
          name: "3D虚拟模型",
          icon: require("../../assets/images/product-icon_6.png"),
          animate: "animate3",
          delay: 0.1,
        },
        {
          name: "物联网感知",
          icon: require("../../assets/images/product-icon_7.png"),
          animate: "animate4",
          delay: 0.2,
        },
        {
          name: "三维可视化智能管控平台",
          icon: require("../../assets/images/product-icon_8.png"),
          animate: "animate5",
          delay: 0.3,
        },
      ],
      features: [
        {
          name: "标识注册、解析和管理能力",
          icon: require("../../assets/img/p_1.png"),
          bg: require("../../assets/images/features-bg_7.png"),
          animate: "animate6",
          txt:"注册解析+标识管理，实现资产数字化管理",
          delay: 0.1,
        },
        {
          name: "“五个一”应用服务能力",
          icon: require("../../assets/img/p_2.png"),
          bg: require("../../assets/images/features-bg_8.png"),
          animate: "animate7",
          txt:"一码多能，实现解析、管控、协同、共享、溯源",
          delay: 0.2,
        },
        {
          name: "多层级平台对接能力",
          icon: require("../../assets/img/p_3.png"),
          bg: require("../../assets/images/features-bg_9.png"),
          animate: "animate8",
          txt:"国家级平台+第三方服务平台，实现数据互联互通",
          delay: 0.3,
        },
        //   {
        //   name: "多设备本地化组网能力",
        //   icon: require("../../assets/img/p_4.png"),
        //   bg: require("../../assets/images/features-bg_9.png"),
        //   animate: "animate15",
        //   txt:"多终端设备本地化组网，实现数据集中和分发",
        //   delay: 0.4,
        // },
        //  {
        //   name: "边缘侧数据处理能力",
        //   icon: require("../../assets/img/p_5.png"),
        //   bg: require("../../assets/images/features-bg_9.png"),
        //   animate: "animate16",
        //   txt:"边缘计算终端+轻量化AI应用，实现高效分析",
        //   delay: 0.5,
        // },
        //  {
        //   name: "高通量数据传输能力",
        //   icon: require("../../assets/img/p_6.png"),
        //   bg: require("../../assets/images/features-bg_9.png"),
        //   animate: "animate17",
        //   txt:"高通量卫星通讯+本地无线通讯，实现数据高速传输",
        //   delay: 0.6,
        // },
      ],
      scenes: [
        {
          name: "产品全生命周期管理",
          icon: require("../../assets/img/p_15.png"),
          animate: "animate9",
          delay: 0.1,
        },
        {
          name: "产品质量追溯",
          icon: require("../../assets/img/p_16.png"),
          animate: "animate10",
          delay: 0.2,
        },
        {
          name: "产品防窜货",
          icon: require("../../assets/img/p_17.png"),
          animate: "animate11",
          delay: 0.3,
        },
        {
          name: "供应链协同",
          icon: require("../../assets/img/p_171.png"),
          animate: "animate11",
          delay: 0.4,
        },
      ],
       scenes2: [
        {
          name: "国际标准码",
          title:"采用符合国际标准的MA标识编码体系",
          bg: require("../../assets/img/p_11.png"),
          icon: require("../../assets/img/p_7.png"),
          animate: "animate20",
          delay: 0.1,
        },
        {
          name: "场景化应用",
          title:"围绕标识解析构建生产、商贸等多领域应用",
          bg: require("../../assets/img/p_12.png"),
          icon: require("../../assets/img/p_8.png"),
          animate: "animate21",
          delay: 0.2,
        },
        {
          name: "定制化服务",
          title:"支持结合客户需求提供个性化应用服务",
          bg: require("../../assets/img/p_13.png"),
          icon: require("../../assets/img/p_9.png"),
          animate: "animate22",
          delay: 0.3,
        },
        {
          name: "SAAS化设计",
          title:"易拓展、易部署、易维护",
          bg: require("../../assets/img/p_14.png"),
          icon: require("../../assets/img/p_10.png"),
          animate: "animate23",
          delay: 0.4,
        },
    
      ],

      animateData: {
        animate: false,
        animate1: false,
        animate2: false,
        animate3: false,
        animate4: false,
        animate5: false,
        animate6: false,
        animate7: false,
        animate8: false,
        animate9: false,
        animate10: false,
        animate11: false,
        animate15: false,
        animate16: false,
        animate17: false,

      },
    };
  },
  mounted() {
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {
    navHandle(id) {
      let target = document.getElementById(id);
      target.scrollIntoView();
    },
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? (this.animateData[i] = true)
          : (this.animateData[i] = false);


          if(i=='animate13' && !isElementNotInViewport(this.$refs[i])){
              setTimeout(()=>{
                this.istwo = false
              },200)
              setTimeout(()=>{
                this.istree=false
              },1000)
          }
           if(i=='animate13' && isElementNotInViewport(this.$refs[i])){
                this.istwo = true
                 this.istree=true
          }
          
      }
    },
    scenesArrowHandle(val) {
      if (val == 1) {
        this.$refs.swiper.prev();
      } else {
        this.$refs.swiper.next();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
    width:100%;
    height:433px;
    background: url(../../assets/img/productVibration_1.png) no-repeat center center;
    background-size:1920px 433px;
    .h3{
        position: relative;
        top:160px;
        width:1180px;
        margin: 0px auto;
    }
    h3{
        width: 578px;
        height: 45px;
        font-size: 34px;
        color: #FFFFFF;
        line-height: 45px;
        width:1180px;
        font-weight: 400;
        margin: 0px auto;
        position: relative;
        top:160px;
    } 
    p{
        height: 19px;
        font-size: 14px;
        color: rgba(255, 255, 255, .49);
        line-height: 19px; 
        width:1180px;
        margin: 0px auto;
        position: relative;
        top:182px;
        
    }
}

.scenes {
  margin-top: 38px;

  .scenes-item {
    width: 290px;
    box-shadow: 0px 2px 14px 9px rgba(77, 77, 77, 0.07);

    img {
      display: block;
    }

    div {
      height: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: #243159;
    }
    
  }
}
.energy-title{
  text-align: center;
}
.survey-title{
  height: 37px;
  font-size: 28px;
  color: #414B59;
  line-height: 37px;
  position: relative;
  top:-23px;
}
.features-title{
  font-size: 28px;
  color: #FFFFFF;
  line-height: 37px;
  position: relative;
  top:-23px;
}
.features-wrap{
  height: 496px;
  background: #3E76E0;
  padding-top:70px !important;
}
.features{
  min-width:270px !important;
  padding:0;
  margin-bottom:35px;
  .features-icon{

  }
  .features-name{
    margin-top: 16px !important;
    height: 48px;
    color: #FFFFFF;
  }
  .features-txt{
    width:310px;
    height: 90px;
    margin:0 auto;
    font-size: 14px;
    color: rgba(255, 255, 255, .66);
    line-height: 30px;
  }
}
.yytxt{
  font-size: 18px !important;
  color: #909399 !important;
  height:67px !important;
}
.toptxt{
  width: 1113px;
  height: 96px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #909399;
  line-height: 32px;
  margin:0 auto;
  text-align: center;
  margin-top:10px;
  span{
        color:rgba(65, 75, 89, 1);
        font-size:20px;
    }
}


.cp-item{
  width:289px;
  height:379px;
  text-align: center;
  padding-top:105px;
  background:#396BE3;
  .cpimg{
    
  }
  .cph3{
    height: 30px;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 24px;
    margin:23px 0 7px 0;
    
  }
  .cptxt{
      width: 175px;
      height: 48px;
      font-size: 14px;
      color: rgba(255, 255, 255, .9);
      line-height: 24px;
      margin: 0 auto;
  }
}
.cp-item:hover{
    background:#396BE3;
    background-image:none !important;
  }
.survey{
  height:805px;
  padding-bottom:0;
  overflow: hidden;
}
.po{
  position: absolute;
}
.topimgbox{
  height:665px;
  width:1261px;
  position: relative;
  top:-69px;
  margin:0 auto;
  left: -28px;
    .img1{
      width:100%;
      height:100%;
    }
    .img2{
      width:881px;
      height:193px;
      top:240px;
      left:216px;
    }
    .img3{
      top:98px;
      left:603px;
    }
    .img4{
      top:421px;
      left:383px;
    }
    .img5{
      top:193px;
      left:124px;
    }
    .img6{
      top:423px;
      right:331px;
    }
    .img7{
      top:200px;
      right:81px;
    }
}
.topimgbox{
  
  .fade1{
    opacity: 0;
    top:340px;
  }
  .fade2{
    opacity: 0;
  }
  .fadeimg1{
    top:48px;
  }
  .fadeimg2{
    top:471px;
  }
  .fadeimg3{
    left:74px;
  }
  .fadeimg4{
      top:473px;
  }
  .fadeimg5{
    right:17px;
  }
 
}
.tran{
    transition: all .7s ease-in;
    -webkit-transition:all .7s ease-in;
  }
</style>